.delete_project_second_step_container {
	display: grid;
	grid-template-areas : 'informationArea'
												'elementsToDelete'
												'confirmationInput'
												'descriptionArea';
	grid-template-columns : 1fr;
}

.delete_project_second_step_information_area {
	grid-area: informationArea;
	height: 50px;
}

.delete_project_second_step_elementsToDelete {
	grid-area: elementsToDelete;
	height: 90px;
	padding-top: 84px;
	width: 100%;
}

.delete_project_second_step_confirmationInput {
	grid-area: confirmationInput;
	padding-top: 36px;
}

.padding-top-6 {
	padding-top: 6px;
}

.delete_project_second_step_descriptionArea {
	grid-area: descriptionArea;
}

.confirm_suprression_message_input {
	font-size: 1.5em;
	font-weight: 500;
	font-family: var(--font-family-title);
}

.delete_project_second_step_information_area_font {
		font-family: var(--font-family-title);
		font-weight: 500;
		font-size: 1.5em;
}

.delete_project_second_step_elementToDelete_area_flex {
  	display: flex;
  	width: 100%;
  	justify-content: space-between;
}

.delete_project_second_step_description_font {
		font-family: var(--font-family-title);
  	font-weight: 550;
  	font-size: 1.2em;
}

.delete_project_second_step_notice_to_export_font {
		font-weight: 500;
		font-size: 1.5em;
		font-family: var(--font-family-title)
}

.text_confirmation_to_delete_project_input {
		width: 40%;
		border-radius: 0.25em;
}

.md-checkbox_accept_terms {
  margin: 8px;
  cursor: pointer;
  padding-right: 0;
  min-width: 12px;
  min-height: 12px;
}

.font-weight_550 {
	font-weight: 550;
}

.delete_project_element_type {
	border-bottom: solid 1px #e1e4e8;
}

.delete_project_element_count {
	display: flex;
	justify-content: flex-end;
}

.padding-top-24 {
	padding-top: 24px;
}

.delete_word_font {
	font-weight: 650;
	font-size: 1.4em;
}

.consequence_of_deletion_container {
	padding-top: 24px;
 	background-color: none;
}

.project_name_font {
	font-weight: 650;
	font-size: 1.3em
}

.agree_consequence_of_deletion_font {
	font-size: 1.3em;
}
