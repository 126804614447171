.dx-gallery-item {
    max-width: 100%;
}

.dx-gallery-item img {
    height: 400px;
    max-width: 100%;
}

.dx-gallery-item .item-price {
    font-size: 20px;
    font-weight: bold;
}