.send-mail {
    z-index: 1000;
    padding: 1.2em;
}

.mail-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mail-form {
    display: flex;
    flex-direction: column;
    margin-top: 1.2em;
}

.mail-form__control {
    margin-bottom: 1.5em;
}

.send-mail__footer {
    display: flex;
}

.send-mail__footer-btn {
    margin-left: auto;
}

.is-disabled {
    cursor: not-allowed !important;
}