.dc-modal .dc-modal-container.size_cannot_delete {
	height: 30vh !important;
	width: 40vw !important;
}

.delete-project-actions_cannot_delete {
	display: flex;
	--saveUriParam-btn-clr: var(--gd-primary);
	justify-content: center;
}

.cannot_suppress_project_notice_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-top: 12px;
}

.cannot_suppress_project_notice_font {
	color: #D0342C;
	font-weight: 550
}

.project_name_font {
	font-weight: 600;
	color: var(--text-color)
}

.cannot_suppress_project_description_container {
	font-size: 1.6em;
	padding-top: 24px;
}

.cannot_suppress_project_description_font {
	font-family: var(--font-family-title);
}
