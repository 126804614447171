.db-deleteall-button {
    color: red;
    margin-top: 12px;
}

.db-tris-head-modal {
    background: #fff;
    border-bottom: 1px black solid;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    padding-top: 8px;
}

.db-api-modal {
    padding: 10px;
}

.db-api-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 10px;
    padding: 10px;
}

.db-api-endpoint {
    font-style: italic;
    color: var(--dc-grey1);
}

.db-aggreg-search {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-right: 0px
}

.db-search-field-thick {
    height: 13px;
    padding: 9px;
    width: 225px;
}

.db-search-field  {
    height: 30px;
    padding: 9px;
    width: 250px;
}

.db-search-ngmat  {
    border-bottom: 2px solid;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.db-search-c {
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.db-search-c input {
    border-bottom: 2px solid;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    width: 135px;
}

.db-step-table {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.db-step-table i {
    color: var(--ag-grey2);
}

.db-step-row-c {
    display: flex;
    flex-direction: row;
    height: 50%;
    align-items: center;
}

.db-step-last {
    width: 20px;
    padding-right: 5px;
    margin-left: 10px;
}

i.fa-trash:hover {
    color: var(--red-dark) !important;
}

.db-init-step {
    color: #fff;
    align-self: center;
}

.db-init-step-table {
    border-radius: 4px;
    margin-left: -5px;
    text-align: center;
    width: 65px;
    height: 100%;
    background-color:  var(--ag-grey2);
}

.db-init-step-table i {
    padding: 5px;
    color: var(--light-text-color);
}

.db-init-step-table i:hover {
    filter: brightness(50%) !important;
}


.db-step {
    border: 1px solid #c9cfd3;
    border-radius: 5px;
    display: inline-block;
    margin: 5px;
    height: 65px;
}


li.dpd-step-row {
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    align-items: center;
}

li.dpd-step-row span {
    width: 35px;
}
li.dpd-step-row i{
    width: 20px;
}
li.dpd-step-row small{
    top: -10px;
    height: 10px;
    margin-left: -11px;
    margin-top: -6px;
}

li.dpd-step-row input {
    margin: 0px 10px 0px 0px;
}

li.dpd-step-row label {
    margin-bottom: 0px;
}

.db-op-present {
    background: var(--green-dark);
    border-radius: 50%;
    position: relative;
    top: -7px;
    margin-left: -6px;
    color: #fff;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
}


.db-ops-container {
    display: inline-flex;
    height: 10px;
    padding-right: 10px;
    flex-grow: 1;
}

.db-ops-smi {
    font-size: 1.2em;
}

.db-ops-container i {
    height: 18px;
}

/*.db-step i {
    color: var(--ag-grey2);
}*/

.db-step:hover {
    background-color: var(--ag-grey4);

}

.db-step i:hover {
    filter: brightness(130%);

}

.db-op-container {
    margin-left: 5px;
    display: flex;
}



.db-op-inactive {
    color: var(--red-dark) !important;
}

.db-op-present-active {
    color: var(--green-dark) !important;
}

.db-op-present-inactive {
    background-color: var(--red-dark) !important;
}

.db-op-present::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.db-step.selected {
    background-color: var(--ag-grey4);
}


.db-step-icon-container{
    display: flex;
    height: 20px;
    width: 80%;
    flex-grow: 1;
}

.db-step-icon-c {
    padding-right: 10px;
}

.step-alias-title {
    font-weight: bold;
    color: var(--dc-primary);
    font-size: 1.1rem;
}
.step-index {
    border-bottom: 2px solid var(--dc-primary);
    width: 90%;
    font-size: 18px;
    font-style: italic;
}

.db-search-c i {
    margin: 0px 5px;
}

.db-exec-bar {
    background-color: var(--ag-grey2);
    padding: 5px 10px 5px 10px;
    border-bottom: 1px solid #ccc;
    position: relative;
    color: var(--light-text-color);
}

.db-exec-bar i {
    color: var(--light-text-color) !important;
}

.db-exec-bar i:hover {
        filter: brightness(70%);
}

.db-model-content {
    padding: 5px;
}

.db-init-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

dnd-nodrag li.db-dnd-col-area {
    background-color: white;
    height: 60px !important;
    border: 2px #ACB6BD dashed !important;
    border-radius: 5px !important;
    line-height: 34px !important;
    margin: 0 auto !important;
    text-align: center;
}

.operation-col {
    display: flex;
    flex-direction: row;
}

.operation-col-type {
    width: 25px;
}

.operation-col-label {
    width: 500px;
    align-self: center;
}

.operation-keep {
    margin: 0px 10px 0px 0px;
    align-self: center;
}

.db-dnd-col-area span {
    font-size: 26px;
    color: #ACB6BD;
}

.db-tris-modal-title {
    margin-left: 12px;
}

.db-tris-valid-button {
    margin-right: 5px;
}

.db-tris-return-button {
    margin-right: 12px;
}

.db-tris-btn-del-item {
    color: black !important;
    margin: 0 10px 0 10px;
}

.db-link-btn-del-critere {
    margin: 8px 0 0 12px;
}

.db-link-btn-del {
    color: black;
    margin-top: 5px;
}

.deletable:hover {

}

.db-delete-dbs-c {
    margin-top: 10px;
    padding-right: 10px;
}

.db-btn-add-step {
    color: #578445 !important;
    margin: 2px 3px 0 0;
}

.db-tag-step-info {
    margin-right: 7px;
}

.db-operation-btn-del-all {
    color: red !important;
}

.db-date-colomn-number-input {
    margin-right: 5px;
}

.db-date-colomn-unit-select {
    margin-right: 15px;
}

.db-date-colomn-label {
   white-space: nowrap;
}

.db-aggreation-deduire-button {
    color: #fff;
    border: 0.5px #2c3c90 solid;
    background-color: #2c3c90;
    font-weight: 100;
    border-radius: 6px;
    height: 30px;
    white-space: nowrap;
}

.db-icon-black {
    color: black !important;
}

.db-col-pivot-right-space {
    margin-right: 7px;
}

.db-formule-mode-tree {
    text-align: center;
}

.db-formule-info-container {
    width: 100%
}

.db-link-critere-label {
    padding-left: 8px !important;
    display: inline-table;
    margin: 5px 0;
}

.db-formule-buttons {
    margin-top: -5px;
}


.db-formule-table tbody tr td {
    border-top: none;
}

.db-exec-error {
    color: var(--text-color) !important;
}
