.opacity-0 {
	opacity: 0;
}

.opacity-1 {
	opacity: 1;
}

.overflow-disabled {
	overflow: hidden;
}

.dc-modal {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	background-color: rgba(0, 0, 0, 0.7);
	transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
	-webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition-duration: 250ms;
	-webkit-transition-duration: 250ms;
}

.dc-modal:focus {
	outline: none;
}

.dc-modal .dc-modal-container {
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;
	min-width: 40%;
	/*border-radius: 1rem;*/
	overflow: hidden;
	padding: 0;
	background-color: var(--light-text-color);
	transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition-duration: 250ms;
	-webkit-transition-duration: 250ms;
	transition-delay: 150ms;
	-webkit-transition-delay: 150ms;
}

.dc-modal .dc-modal-container.fullscreen {
	width: 98% !important;
	height: 98% !important;
	max-width: 98% !important;
}

.dc-modal .dc-modal-header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: stretch;
	align-items: center;
	/*padding: 10px;*/
	border-bottom: 1px solid #e5e5e5;
	background-color: #f4f6f7;
	/*background-color: var(--gd-primary);*/
	/*color: var(--light-text-color);*/
	padding: 0.25em 0.5em 0.25em 1em;
	font-size: 2em;
}

.dc-modal .dc-modal-header .close-modal {
	margin-left: auto;
}

.dc-modal .dc-modal-header .dc-modal-title {
	flex-grow: 1;
	text-align: center;
}

.dc-modal .dc-modal-content {
	overflow: hidden;
}

/*.dc-modal .dc-modal-content > * {*/
/*	margin: 2em auto;*/
/*}*/

/*.dc-modal .dc-modal-content > img {*/
/*	margin: auto;*/
/*	max-width: 100%;*/
/*}*/

.dc-modal .dc-modal-content.padded-content {
	padding: 2rem;
}

.dc-modal .dc-modal-content.no-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.dc-modal .dc-modal-actions {
	flex-shrink: 0;
	text-align: center;
	padding: 1em;
	color: white;
}

.dc-modal .dc-modal-actions-without-color {
	flex-shrink: 0;
	text-align: center;
	padding: 1em;
}

.dc-modal .dc-modal-actions.modal-dark-footer {
	background-color: var(--ag-grey1);
}
