@font-face {
  font-family: dc-custom;
  src:  url('../fonts/dc-custom.eot?qonp3k');
  src:  url('../fonts/dc-custom.eot?qonp3k#iefix') format('embedded-opentype'),
    url('../fonts/dc-custom.ttf?qonp3k') format('truetype'),
    url('../fonts/dc-custom.woff?qonp3k') format('woff'),
    url('../fonts/dc-custom.svg?qonp3k#dc-custom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dc-icon-"], [class*=" dc-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: dc-custom, sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dc-icon-api::before {
  content: "\e900";
}

.dc-icon-API::before {
  content: "\e901";
}

.dc-icon-union::before {
  content: "\e902";
}

.dc-icon-log-scale::before {
  content: "\e903";
}

.dc-icon-cut_between_words::before {
  content: "\e904";
}

.dc-icon-align_middle_simple::before {
  content: "\e905";
}

.dc-icon-align_middle::before {
  content: "\e906";
}

.dc-icon-position_outside::before {
  content: "\e907";
}

.dc-icon-position_inside::before {
  content: "\e908";
}

.dc-icon-long-dash::before {
  content: "\e909";
}

.dc-icon-dash::before {
  content: "\e90a";
}
