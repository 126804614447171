.dh-source-bordered-row{
   border-radius: 6px;
   height : 35px;
   margin : 10px;
   border: 0.5px gray solid;
}


.dh-source-bordered-row:hover {
  background-color: #f5f5f5;
  border: 1px #f5f5f5 solid;
}


.middle-text {
  font-weight : bolder;
  font-size : 14px;
}