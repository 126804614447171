.project_label_container_layout {
    border: 1px solid var(--grey-background-color);
    display: flex;
    width: 90%;
}

.project_administrator_style {
    display: flex;
    padding: 0.313em 0.313em 0.313em 0;
    font-family:  var(--font-family-title);
    font-weight: 300;
    font-size: 1em;
    color: var(--dc-primary-color);
}

.unduplicated_project_container {
	display: flex;
}

.project_currentUser_fullName_style {
    padding: 0.313em;
    display: flex;
    align-items: center;
    font-family:  var(--font-family-title);
    font-weight: 400;
    font-size: 1em;
    color: var(--ag-primary-color);
}

.footer_text_style {
    position: static;
    height: 1.125em;
    left: 0;
    top: 2.375em;
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 0.563em;
    line-height: 1.125em;
    display: flex;
    align-items: center;
    color: var(--ag-primary-color);
}

.project_label_style {
    font-size: 0.75em;
    font-weight: 600;
    font-family: var(--font-family-title);
    color: var(--ag-primary-color);
}

.radio_buttons_input {
    font-size: 1em;
    display: flex;
    align-items: center;
}

.radio_buttons_input_choice {
    font-size: 0.75em;
    font-weight: 400;
    font-family: var(--font-family);
    padding-left: 0.25em;
}

.radio_buttons_input_choice label {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    padding-left: inherit;
}

.unduplicated_data_information_layout {
    background-color: var(--grey-background-color);
    padding: 0.625em 1.25em;
}

.info_notice_layout {
    display: flex;
    align-items: center;
    padding-left: 1.125em;
}

.info_notice {
     font-family:  var(--font-family-title);
     font-weight: 600;
     font-size: 1.1em;
     color: var(--ag-primary-color);
}

.info_notice_content {
   font-size: 0.75em;
   font-weight: 400;
   font-family: var(--font-family);
}
