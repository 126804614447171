.depot-import-container {
    display: flex;
}

.depot-deleteall-button {
    margin-right: 12px;
    margin-top: 10px;
    color: red;
}

.sql-depot-checkbox {
    text-align: center;
}

.new-head-button {
    color: #fff;
    background-color: #578445;
    border-radius: 6px;
    height: 30px;
    border: 0.5px #578445 solid;
    font-weight: 100;
    white-space: nowrap;
}

/* sans connector form */

/* immitate col-md-? */
@media (min-width: 1385px) {
   .depot-md1-champ {
        width: 8%;
   }

   .depot-md2-champ {
       width: 16%;
   }

   .depot-md3-champ {
        width: 24%;
   }

   .depot-md4-champ {
       width: 32%;
   }

   .depot-md5-champ {
      width: 40%;
   }

   .depot-md8-champ {
       width: 65%;
   }

   .depot-md1-label {
       width: 8%;
   }

   .depot-md2-label {
      width: 16%;
   }

   .depot-md12 {
     width: 100%;
   }


}

@media (max-width: 1385px) {
    .chemin-input-margin {
        margin-bottom: 12px;
    }
}

@media (min-width: 1750px) {
    .access-md1-champ {
            width: 8%;
    }

    .access-md2-champ {
       width: 16%;
    }

    .access-md3-champ {
        width: 24%;
    }

    .access-md4-champ {
       width: 32%;
    }

    .access-md5-champ {
       width: 40%;
    }

    .access-md6-champ {
           width: 48%;
    }


    .access-md7-champ {
           width: 56%;
    }

    .access-md9-champ {
       width: 72%;
    }

    .access-md12-champ {
       width: 100%;
    }
}

label {
    white-space: nowrap;
}

.access-connector-container {
    display: flex;
    justify-content: flex-start;
}


@media (max-width: 1750px) {
    .access-connector-label {
        flex-basis: 12rem;
    }
}

@media (max-width: 768px) {
    .media-width-100 {
        width: 100% !important;
    }
}

.sql-depot-container {
    display: flex;
    justify-content: space-between;
}

.entite-refresh-button {
    margin: 10px 12px 0 5px;
    color: black;
}

.http-header-delete-icon {
    color: black;
    margin-top: 8px;
}

.switch-depot {
	margin: 3px 4px 0px 4px !important;
	float: left !important;
}
