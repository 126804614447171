 .picto-header-modal {
    background: #fff;
    border-bottom: 1px black solid;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-left: 0;
    padding-right: 0;
}

.picto-close-button {
    color: black;
    opacity: 1 !important;
    margin: 3px 5px 0 !important;
}

.metadata-modal-close-button {
    margin: 3px 5px 0 !important;
}

.picto-button-container {
    display: flex !important;
}

.picto-refresh-button {
    margin-top: -10px;
    margin-right: -10px !important;
}

.picto-refresh-button:hover {
   background-color: #fff !important;
}

.picto-button-container > a:hover {
    background-color: #fff !important;
}

.picto-tab-container {
    margin-left: 12px;
    margin-right:12px;
}

.picto-camera-icon {
    margin-right: 5px;
}