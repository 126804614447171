.dc-modal-content.remote-files-explorer-content {
	padding: 2em;
	font-size: 1.25rem;
}

.dc-modal .dc-modal-container.maxsize {
	height: 100vh !important;
	width: 100vw !important;
}

.remote-files-explorer__header-close {
	margin-left: auto;
}

.remote-files-explorer-content {
	display: flex;
	flex-direction: column;
}

.remote-files-explorer-actions {
	margin-top: auto;
	margin-left: auto;
	--saveUriParam-btn-clr: var(--gd-primary);
}

.remote-files-explorer-actions__save-btn {
	border-radius : 0.25em;
	background-color: var(--saveUriParam-btn-clr);
	color: white;
}

.remote-files-explorer-actions__save-btn:hover {
	color: white;
}

.remote-files-explorer-content__manager {
	height: calc(100vh - 200px) !important;
}
