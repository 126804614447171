.dc-modal .display-data-modal-container {
	height: 90vh; width : 80vw; background-color: var(--light-text-color);
	transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition-duration: 250ms;
	-webkit-transition-duration: 250ms;
	transition-delay: 150ms;
	-webkit-transition-delay: 150ms;
}

.display-depot__header-close {
	margin-left: auto;
}

.display-depot-content {
	display: flex;
	flex-direction: column;
}

.visualize-repository-data_title {
	font-size: 16px;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
	padding-left: 24px;
}

.hidden-columns {
	color: orange;
	font-size: medium;
	margin-top: 5px;
	margin-bottom: 5px;
}

.bold_column {
	font-weight: bold;
}
