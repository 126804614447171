.date_input_width  {
	width: 85%;
}

.import_projects_context {
	display: flex;
	border-bottom: 1px #ABABAC solid;
	padding-bottom: 8px;
	justify-content: space-between;
}

.width_220 {
	width: 220px;
}

.width_300 {
	width: 300px;
}

.width_230 {
	width: 230px;
}

.padding-12 {
	padding: 12px 0;
}

.import_projects_context_column {
 	font-weight: 600;
	font-size: 12px;
	font-family:  var(--font-family-title);
	padding-left: 12px;
}

.width_100_percent {
	width: 100%;
}


.import_projects_context_line {
  font-family: var(--font-family);
	font-weight: 500;
	font-size: 14px;
	width: 100%;
	padding-left: 12px;
	padding-right: 32px;
}

.import_projects_context_list_elem {
  font-family: var(--font-family);
	font-weight: 500;
	font-size: 14px;
}