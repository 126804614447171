.project-container {
    max-height: 500px;
    overflow-y: auto;
}

.project-container-item {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-with-text {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 3.6em;
  text-align: justify;
  padding-right: 1em;
}

.block-with-text::before {
  content: "…";
  position: absolute;
  right: 0;
  bottom: 0;
}

.block-with-text::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

.project-move-icon-color {
    color: #525252;
}

.move-item-selected {
    background: var(--ag-grey4);
}

.item-icon-rad {
    display: inline-block !important;
    border-radius: 100px !important;
    box-shadow: 0 0 2px #525252 !important;
    padding: 3px !important;
}