.dc-dashboard-header-wrapper {
    position: relative;
}

.dc-dashboard-header-content {
    z-index: 1001;
    background-color : #fff;
}

.dc-dashboard-header-content-fixed {
    position: fixed;
    top: 107px;
    border-radius: 6px;
    border: 0.5px #0E4C92 solid;
    border-top: none;
    width: 84%;
    padding-left: 0;
    margin-left: -2px;
}

.ellipsis-text-two-lines {
	overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-conf-centering  {
    border: 1px solid #acb6bd;
    height: 30px;
    top: 50%;
    padding-top: 3px;
    padding-left: 10px;
    color: #8a8a8a;
}

.fixed_width {
	width: 120px;
}

.fixed_with_select_container {
	width: 90px;
}

.select_operator_container {
	font-size: 10px;
	padding: 2px 2px 3px 3px;
}
