.dc-modal-content.delete-project-content {
	padding: 2em;
	font-size: 0.6rem;
}

.delete-project-modal-title-name {
		font-weight: 500;
    font-size: 0.8em;
    font-family: var(--font-family-title);
    color: var(--ag-primary);
}

.delete-project-modal-title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-weight: 500;
    font-size: 0.8em;
    border-top: 12px;
    font-family: var(--font-family-title);
    color: var(--ag-primary);
    text-overflow: ellipsis;
}

.project-name-text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 400px;
    display: block;
    overflow: hidden
}

.title_name_container {
	display: flex;
}

.width_270 {
	width: 270px;
}

@media screen and (min-width: 1708px) {
	.dc-modal .dc-modal-container.size {
			height: 55vh !important;
			width: 58vw !important;
	}
}
@media screen and (max-width: 1708px) and (min-width: 1050px) {
	.dc-modal .dc-modal-container.size {
			height: 55vh !important;
			width: 90vw !important;
	}
}

@media screen and (max-width: 1050px) and (min-width: 400px) {
	.dc-modal .dc-modal-container.size {
			height: 55vh !important;
			width: 100vw !important;
	}
}

.delete-project__header-close {
	margin-left: auto;
}

.delete_project_notice {
	font-size: 1.5em;
	font-weight: 500;
	padding-bottom: 4px;
	font-family: var(--font-family-title);
}

.delete-project-content {
	height: 600px;
	overflow-y: auto;
}

.delete_project_step_message {
	font-weight: 500;
	font-size: 1.4em;
	font-family: var(--font-family);
	color: var(--ag-primary);
}

.delete-project-params-container {
	display: grid;
	grid-template-columns: minmax(400px, auto) minmax(400px, auto) 1fr;
	grid-template-areas:
		"paramKey paramValue paramAction";
	grid-column-gap: 1em;
	grid-row-gap: 1.25em;
}

.delete-project-params__key {
	grid-area: paramKey;
}

.delete-project-params__value {
	grid-area: paramValue;
}

.delete-project-params__action-add {
	grid-area: paramAction;
	color: var(--addParam-btn-clr);
}

.delete-project-params__header {
	margin: 1.25em 0 0.1em 0;
}

.delete-project-params__entry {
	margin: 0.45em 0 0.25em 0;
}

.delete-project-params__header-lbl {
	display: flex;
	justify-content: center;
}

.delete-project-params__action-remove {
	grid-area: paramAction;
}

.delete-project-params__action-remove-icon:hover {
	color: var(--removeParam-btn-hvr-clr);
}

.delete-project__uri-label {
	display: flex;
	flex-direction: column;
	width: 18em;
}

.delete-project__base-path__label {
	font-weight: bold;
}


.delete-project__full-url {
	margin-top: 2em;
	display: flex;
	flex-direction: column;
}

.previous_next_buttons{
  display: flex;
	margin-top: auto;
	margin-left: auto;
}

.next_step_button {
	padding-left: 10px;
}

.delete_project_button_width {
	width: 24%;
}

.delete-project-actions {
	display: flex;
	--saveUriParam-btn-clr: var(--gd-primary);
}

.delete-project-actions__save-btn {
	border-radius : 0.25em;
	background-color: var(--saveUriParam-btn-clr);
	color: white;
}

.delete-project-actions__save-btn:hover {
	color: white;
}

.action-color {
	color: black;
}
