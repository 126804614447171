.import-label {
    margin-top: 5px;
}

.position-right{
    text-align: right;
}

.importDb-execute-button {
    text-align: right;
    padding-right: 0 !important;
}