.element_details_container {
    display: grid;
    grid-template-areas: "dataBlockInformations description"
                         "metadataInformations description"
                         "lastUpdate actions";
    grid-template-columns:  0.6fr 0.4fr;
    grid-template-rows: 15% 60% 25%;
    height: 70vh;
    --description-container-color: #C0C0C0;
    --element-container-color: #BFEBEB;
    --action-color: #006666;
    --dark-grey-button-color: #A9A9A9;
}
.element_details_dataBlockInformations {
    grid-area: dataBlockInformations;
}

.element_details_metadataInformations {
    grid-area: metadataInformations;
}

.element_details_lastUpdate {
    grid-area: lastUpdate;
}

.element_details_description {
   grid-area: description;
}

.element_details_actions {
  grid-area: actions;

}

.datablock_informations_flex_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.element_container {
    border: 1px solid transparent;
    border-radius : 0.25em;
    background-color: var(--element-container-color);
    display: flex;
    justify-content: space-around;
    width: 20%;
    padding: 0.75em;
    height: 60%;
}

.element_style {
    font-family: var(--font-family-title);
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
}

.row_column_element_style {
     background-color: var(--ag-grey4);
     width: auto;
     height: 60%;
}

.image_container {
    width: auto;
    background-color: white;
    border-radius : 0.25em;
}

.meta_informations_container {
    display: flex;
    flex-direction: column;
    height: 25%;
}

.metadata_informations_style {
    font-family: var(--font-family-title);
    font-size: 1.10em;
    font-weight: bold;
}

.metadata_informations_text {
    color: var(--text-color);
    text-align: justify;
    margin-top: 0.8em;
    font-size: 1.25em;
    word-wrap:anywhere;
}

.last_update_container {
    display: flex;
    flex-direction: column;
    padding: 0.8em;
    background-color: var(--ag-grey4);
    border-radius: 0.25em;
    height: 70%;
}

.date_time_style {
    font-style: italic;
    font-family: var(--font-family);
    font-weight: normal;
    font-size: 0.8em;
    margin-left: 1em;
}

.element_details_footer {
    padding: 0.8em 0.8em 0.8em 0em;
}

.footer_text_style {
   font-style: italic;
	 font-family: var(--font-family);
   text-align: justify;
   padding-top: 1em;
   font-size: 0.8em;
}

.description_container {
   padding: 20px;
   border: 1px solid var(--description-container-color);
   border-radius: 0.25em;
   height: 90%;
}

.description_style {
	 font-family: var(--font-family);
   text-align: justify;
   font-weight: 600;
   height: 95%;
   overflow-y: auto;
}

.description_paragraph_style {
   width: 97%;
   margin-top: 0.8em;
}

.actions_container {
  display: flex;
  flex-direction: column;
}

.actions_style {
  display: flex;
  justify-content: flex-end;
}

.action_style {
  color: var(--action-color);
  margin-top: 0.8em;
}

.action_style--disabled {
  color: var(--ag-grey3) !important;
  cursor: not-allowed;
  pointer-events: none;
}

.button_style {
  border: 1px solid var(--dark-grey-button-color);
  margin-top: 0.8em;
  background-color:  var(--description-container-color);
}

.button_text_style {
  font-family: var(--font-family-title);
  font-size: 16px;
  font-weight: 600;
}

.catalog-element-modal {
  width: 80%;
}

.catalog-element-tags {
  display : flex;
  padding: 10px 0px 10px 0px;
}

.catalog-element-tag {
  display : flex;
  justify-content: space-around;
}

.row_column_element_rows {
     width: 35%;
}

.row_column_element_columns {
     width: 25%;
}

.catalog-spinner {
  color: var(--ag-grey1);
}

.no_information_catalog {
    font-style: italic;
}



