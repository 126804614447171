.delete_project_final_step_container {
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;

}

.delete_project_final_step_element_to_delete_area {
 padding-right: 12px;
}

.delete_project_final_step_element_notice_font {
	font-family: var(--font-family-title);
	font-weight: 500;
	font-size: 1.5em;
	padding: 12px;
}

.verification_code_to_delete_project_input {
		border-radius: 0.4em;
		width: 20rem;
}


.delete_project_resend_code_font {
	font-size: 1.4em;
	font-weight: 500;
}

.send_validation_code_to_delete_project_font {
	font-family: var(--font-family-title);
	font-weight: 530;
	font-size: 1.3em;
	padding-left: 24px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.padding-12:nth-child(1) {
	padding: 12px;
}

.new_code_is_sent_font {
	font-size: 1.6em;
	font-weight: 550;
	color: var(--saveUriParam-btn-clr);
	font-family: var(--font-family-title);
}

.verification_code_to_delete {
	display: flex;
 	padding: 12px;
  width: 100%;
}
