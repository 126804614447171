:root {
    --project-members-input-width: 30em;
    --project-add-members-input-width: 50em;
}

#projects_div {
    height: 100%;
    margin-bottom: 40px;
}

.dc-card-pj-selected {
    box-shadow: 3px 3px 2px 1px red !important;
}


.pj-toolbar md-input-container{
    margin: 0;
}

.project-search-btn {
    font-size : 35px;
    color : var(--ag-grey1);
}

.project-add-btn {
    padding-left: 20px;
    font-size : 35px;
    color : var(--green-dark);
}

.dc-card-pj-header {
    height : 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    padding: 0 10px;
    background-color : var(--ag-grey4);
    border-bottom: 3px solid;
}

.dc-public {
    border-color: var(--green-dark);
}

.dc-private {
    border-color: var(--red-dark);
}

.dc-expired {
    border-color: var(--ag-grey2);
}

.dc-public-color {
    color: var(--green-dark);
}

.dc-public i.p-icon  {
    color: var(--green-dark);
}

.dc-upper {
    text-transform: uppercase;
}

.dc-private-color {
    color: var(--red-dark);
}

.dc-private i.p-icon {
    color: var(--red-dark);
}

.dc-expired i.p-icon {
    color: var(--ag-grey2);
}

.dc-pj-btn-group {
    flex-grow: 0;
}

.dc-pj-btn-group i {
    margin: 0 2px;
}

.dc-card-pj-body {
    padding: 10px 10px 0;
}

.dc-card-pj-footer {
    padding: 0 10px 10px;
    line-height: 1.2rem;
}

.dc-card-pj-body .dx-texteditor.dx-editor-outlined {
    border : none;
    width: 100%;
}

.dc-card-pj-img {
    display: flex;
    align-items: center;
    height: 125px;
    margin-right: 5px;
}

.dc-card-pj-img img {
    height: 90px;
    width: auto;
    object-fit: contain;
    align-self: center;
}

.dc-card-pj-desc {
    margin-top: 5px;
    color: var(--text-color);
    word-wrap:anywhere;
    overflow: hidden;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
}

.dc-card-pj-expired {
    height: 125px;
}

.dc-card-pj-desc-expired {
    color: var(--ag-grey2);
    font-size: 30px;
}

.dc-card-pj-expired-img {
    font-size : 70px;
    color : var(--ag-grey2);
}

/***************  TAGS *************/
.dc-card-pj-tags {
    height: 50px;
    max-height: 50px;
    margin-top: 5px;
}

/************ ***********************/

.dc-card-pj-icons {
    color: var(--ag-grey2);
    height: 30px;
    font-size: 15px;
    align-items: center;
    margin: 5px 0 0 5px;
}

.dc-card-pj-icons span {
    margin-left: 5px;
    margin-right: 10px;
    font-size: 18px;
}

/************ ***********************/

.dc-card-pj-access {
    color : var(--ag-grey3);
    font-style : italic;
    overflow: hidden;
    flex-grow: 1;
}

.dc-card-pj-favicon {
    font-size : 15px;
    color: var(--ag-grey3);
    margin: 0;
}

.dc-card-pj-favicon > i {
    margin: 2px;
}

.dc-card-pj-favicon > i.selected {
    color: #FB8500;
}

/************ ***********************/
.dc-project-form-content {
    border-left: 3px solid var(--dc-secondary);
}

.dc-project-form-number{
   border-radius: 13px;
   height : 26px;
   width: 26px;
   text-align: center;
   font-weight : bolder;
   background-color :  var(--dc-secondary);
}

.dc-privacy-switch-c {
    display: flex;
    align-items: center;
}

.dc-privacy-switch-c label {
    margin-bottom: 0;
}

.dc-privacy-switch-c i {
    margin: 0 5px;
}

.dc-privacy-switch {
    margin: 0 5px;

}

.dc-privacy-switch > md-switch .md-bar {
    background-color: var(--ag-grey3);
}

.dc-privacy-switch > md-switch .md-thumb {
    background-color: var(--red-dark);
}

.dc-privacy-switch > md-switch .md-thumb::before {
    content: '';
}

.dc-privacy-switch > md-switch.md-checked .md-thumb {
    background-color: var(--green-dark);
}

.dc-disabled-text{
   color: gray;
   font-weight : normal;
}

.dc-left-bordered-td{
  padding : 4px !important;
  border-left: 1px solid #ced4d8 !important;
  border-top: none !important;
}

.dc-hover-td:hover{
   background-color : var(--ag-grey3);
}

.dc-project-title{
   font-size : 30px !important;
   font-weight : bolder !important
}

.dc-project-title-count{
   color : var(--ag-grey2) !important;
   font-size : 20px !important
}

.toggle-on.btn {
    padding-right: 24px;
}

.toggle-on {
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 0;
}

.toggle-group, .toggle-off, .toggle-on {
    position: absolute;
    top: 0;
    bottom: 0;
}

.btn-pj-on {
    color: #fff;
    background-color: var(--green-dark);
}

.btn-pj-off {
    color: #fff;
    background-color: var(--red-dark);
}

.btn-pj-off:hover, .btn-pj-on:hover {
    color: #fff;
}

.pj-step {
    background-color: var(--dc-secondary);
    color: #fff;
    border-radius: 50%;
    padding: 8px 12px;
    text-align: center;
}

.pj-cal {
    width: 180px !important;
}

md-tabs .md-tab.md-active {
    color: var(--ag-grey2);
    font-weight: bold;
}

md-tabs .md-tab {
    margin: 5px;
    padding: 10px 20px;
}

md-pagination-wrapper {
    width: 100% !important;
}

md-tab-item.md-active .pj-step {
    background-color: var(--dc-primary);
}

.pj-step-label {
    text-transform: none;
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
    color: var(--dc-secondary);
    background-color: var(--dc-secondary);
    height: 5px;
}

/********* Rights Template *********************/
.temp-container {
    /* max-width: 1400px; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 10px;
}

.temp-col {
    display: flex;
    justify-content: space-between;
    width: 160px;
    flex-direction: column;
    border-left: solid 1px var(--ag-grey3);
}

.temp-header {
    font-weight: bold;
}

.no-left-border {
    margin-left: -1px;
    border-left: 2px solid var(--content-bg-color) !important;
}

.header-last {
    height: 28px !important;
    border-bottom: 2px solid var(--ag-grey3);
}

.pj-expiration {
    flex: auto;
    justify-content: flex-end;
    color: var(--ag-grey2);
}

.temp-scroll-col {
    width: 1400px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.perm-row {
    display: flex;
    flex-direction: row;
    height: 38px;
}

.perm-header-row {
    display: flex;
    flex-direction: row;
    height: 25px;
}

.perm-row-item {
    width: 80px;
    flex: auto;
    text-align: center;
    margin: auto;
}



.perm-body-row {
    display: flex;
    flex-direction: row;
}

.perm-temp-body {
    display: flex;
    justify-content: space-between;
    width: 160px;
    font-weight: bold;
    flex-direction: column;
}

.perm-first {
    width: 100%;
    padding-left: 10px;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.perm-first-gd {
    border-left: 5px solid var(--gd-accent);
}

.perm-first-hd {
    border-left: 5px solid var(--hd-accent);
}

/****************   ********************/

/**************** Contextes ********************/
.context-container {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.ctx-scroll-col {
    width: 900px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
}

.ctx-col {
    display: flex;
    justify-content: space-between;
    width: 250px;
    flex-direction: column;
    border-left: solid 1px var(--ag-grey3);
}

.ctx-col-first {
    width: 350px !important;
    border-left: 0 !important;
}

.ctx-header-row {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.ctx-row {
    display: flex;
    flex-direction: row;
    height: 40px;
}

.ctx-row-centered {
    align-self: center;
}

.ctx-row-item {
    width: 125px;
    flex: auto;
    text-align: center;
    margin: auto;
    flex-direction: row;
    display: inline-flex;
}

.icon-number {
    margin-left: 5px;
    margin-right: 5px;
}

/*
 EDIT PROJECT USERS
 */

.edit-project__members {
    width: var(--project-members-input-width);
}


.edit-project__member {
    display: flex;
    align-content: flex-start;
    align-items: baseline;
}

.edit-project__member__roles {
    display: flex;
    justify-content: flex-start;
    width: 4em;
}

.edit-project__member__roles > i {
    margin-left: 0.2em;
    margin-right: 0.2em;
}

.edit-project__member__name {
    margin-left: 0.75em;
}

.edit-project-autocomplete li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: normal;
}

.edit-project-autocomplete li:last-child {
    border-bottom-width: 0;
}

/*
 ADD PROJECT USERS
 */

.add-project-members {
    margin-bottom: 5em;
}

.add-project-members__chips-container {
    margin: auto;
}

.add-project__members {
    margin-top: 2em;
    width: var(--project-add-members-input-width);
}

.add-project__members-autocomplete li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: normal;
}

.add-project__members-autocomplete li:last-child {
    border-bottom-width: 0;
}

.add-project__member {
    display: flex;
    align-content: flex-start;
    align-items: baseline;
}

.add-project__member__roles {
    display: flex;
    justify-content: flex-start;
    width: 4em;
}

.add-project__member__roles > i {
    margin-left: 0.2em;
    margin-right: 0.2em;
}

.add-project__member__name {
    margin-left: 0.75em;
}

.project-link {
	display: flex;
	align-items: center;
}

.project-link__label {
	width: 10%;
}

.project-link__input {
	width: 100%;
}

.project-params__exposition-root {
    width: 50%;
}

.project-params__invalid {
    font-size: 0.85rem;
    color: #f44336;
}
