.cdk-overlay-container {
	z-index: 9999 !important;
}

.mat-dialog-container {
	padding: 0 !important;
}


app-modal-override-class {
	mat-dialog-container {
		padding: 0;
		display: flex;
		flex-direction: column;
	}
}
