.is-forbidden {
    cursor: not-allowed;
}

.send-mail-tooltip-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.admin-members-group-label, .regular-members-group-label {
    margin-bottom: 0.35em;
    font-weight: bold;
}

.send-mail-recipient {
    display: flex;
    align-items: baseline;
    width: 100%;
    margin-bottom: 0.5em;
}

.send-mail-recipient__fullname {
    margin-right: 3em;
}

.send-mail__btn {
    margin-left: auto;
}

.project-admin-members, .project-regular-members {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.project-card__entry {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: baseline;
}

.project-card__members-contexts {
    display: flex;
    align-items: center;
    color: var(--ag-grey2);
    height: 30px;
    font-size: 15px;
    margin: 5px 0;
}

.project-card__expiration, .project-card__availability {
    display: flex;
    align-items: center;
    color: var(--ag-grey2);
    height: 30px;
    font-size: 15px;
    margin-left: auto;
}

.project-card__members-contexts >.project-card__members {
    display: flex;
    margin-right: 0.25em;
}

.project-card__members-contexts > .project-card__contexts {
    display: flex;
    margin-left: 0.25em;
}

.project-card__members-icon, .project-card__contexts-icon {
    margin-right: 0.25em;
}
