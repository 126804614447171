.p-progress-main-container {
    display: flex;
    justify-content: center;
}

.p-progress-message {
    height: 40px;
    font-family: Montserrat, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.p-progress-bar-container {
    padding: 10px;
    display: flex;
}

.p-progress-project-title {
    padding-right: 12px;
}

.p-progress-text {
    font-family: Kodchasan, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #333;
}

.p-progress-to-container {
    padding-right: 12px;
    display: flex;
    justify-content: flex-end ;
    width :  209px;
}

.p-progress-sub-message {
    height: 25px;
    font-family: Montserrat, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.p-progress-sub-container {
    text-align: center;
    padding-top: 12px;
}

.p-progress-bar {
    padding-top: 12px;
}
