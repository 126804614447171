.module-switcher {
  display: block;
  margin: 0 0.5rem 0 1rem;
	font-size: 20px;
}

.module-switcher__module-icon {
	width: 3em;
	aspect-ratio: 1;
}

.module-switcher__dropdown-menu {
	display: flex;
	gap: 0.25em;
}
