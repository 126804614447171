#inputMappingModal input {
    width: 35em;
}

.step-input-modal {
    width : 1300px;
}

.step-input-modal-body {
    width: 100%;
    height: 800px;
}

.step-input-modal__header {
    height : 50px;
    background-color : inherit !important;
}

.step-input-source__filter {
    display: flex;
    align-items: center;
}

.step-input-source__filter-btn {
    margin-left: 0.5em;
}

.step-input-mapToColumn__filter {
    display: flex;
    align-items: center;
}

.step-input-mapToColumn__filter-btn {
    margin-left: 0.5em;
}

.step-input__add-column {
    margin-left: 2.5em;
}

.step-input__linked-columns {
    overflow: auto;
    height: 650px;
}

.is-sortable {
    cursor: move;
}

.is-disabled {
    color: var(--ag-grey5);
}


.step-input__column-type {
    width: 90%;
}

.step-input__column-type__icon {
    width : 50px;
    display : inline-block;
}

.step-input__column-label {
    display : inline-block;
}

.step-input__column__map-to-properties {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.step-input__column-icons {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 4em;
    justify-content: space-around;
}

.step-input__column-edit {
    color: black;
    cursor: pointer;
}

.step-input__column-remove {
    color: black;
    cursor: pointer;
}

.step-input__column-remove--disabled {
    color: var(--ag-grey5);
    cursor: not-allowed;
}


.step-input__column__add-new {
    overflow-y: hidden;
    min-height: 260px;
}

.step-sortable-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height : 2.25em;
    margin-bottom: 0.3em;
}

.handle-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-sortable-input:hover {
    background-color: #d9e3ef;
}

.step-input__add-column-modal {

}

.step-input__add-column-modal__validate-btn {
    color : #578445;
    background-color: #fff;
    border: 0.5px #578445 solid;
}

.step-input__add-column-modal--is-disabled {
    border: 0.5px var(--ag-grey5) solid;
    color: var(--ag-grey5);
}

.step-input__add-column-modal--is-disabled:hover {
    cursor: not-allowed;
    color: var(--ag-grey5);
}

#newColumnModal input[type="text"], #newColumnModal select {
    width: 25em;
}

.step-input__add-column-modal__btn-container {
    display: flex;
    flex-direction: row-reverse;
}

.step-input__add-column-modal__list-input {
    display: flex;
}
