:root {
	--ag-grey1: #363636;
	--ag-grey2: #585858;
	--ag-grey3: #C5C7C8;
	--ag-grey4: #F1F1F0;
	--ag-grey5: hsl(0, 0%, 75%);
	--dc-primary: #585858;
	--dc-secondary: #9D9D9D;
	--hd-accent: #EE4A4A;
	--hd-primary: #FB8500;
	--hd-secondary: #FFB703;
	--gd-accent: #00B0B0;
	--gd-primary: #09575C;
	--gd-secondary: #67A5AD;
	--pydc-primary: #3673A2;
	--pydc-secondary: #F8C946;

	--warning-color: #FB8500;
	--alert-color: #C9302C;

	--text-color: var(--ag-grey1);
	--light-text-color: #fff;

	--sidebar-color: #22577A;

	--green-dark: #219653;
	--red-dark: #C9302C;
	--blue-dark: #337ab7;
	--job-green: #4ee400;

	--btn-add: #578445;

	--content-bg-color: #ffffff;
}

$tablet-breakpoint-width: 1200px;
