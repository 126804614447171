.ui-grid-header {
  border-bottom: 1px solid #d4d4d4;
}

.ui-grid-top-panel {
  color:white;
  background: #000;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #333), color-stop(1, #333));
}

.ui-grid-filter-input{
	color : #000;
}

.ui-grid-pager-control .ui-grid-pager-max-pages-number {
    vertical-align: middle;
}

.ui-grid-pager-panel{
	position: relative;
}

input[type="text"].ui-grid-filter-input{
	background: #EED !important;
}

.ui-grid-cell input[type="checkbox"] {
    margin: 3px 0 0 6px !important;
    width: auto;
}

.ui-grid-cell-contents {
  padding: 5px;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
}

.ui-grid-cell {
  overflow: hidden;
  float: left;
  background-color: inherit;
  border-right: 1px solid;
  border-color: #d4d4d4;
  box-sizing: border-box;
}

.ui-grid-header-cell {
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid;
  border-color: #d4d4d4;
  display: table-cell;
  user-select: none;
  width: 0;
}

.ui-grid-row {
  clear: both;
}

.ui-grid-contents-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
