.head-icon {
    color: black;
    margin-top: 10px;
}

.entity-deleteall-button {
    color: red;
    margin-top: 10px;
}

.entity-left-container {
    padding-left: 0;
    padding-top: 7px;
    display: flex;
    flex-wrap: nowrap;
}

.cache-persitent-button {
    width: 100%;
    display: block;
    padding-left: 15px;
    margin-left: 0 !important;
}

.cache-persitent-icon {
    float: left;

   /* margin-right: 7px; */
}

.persitent-font {
    margin-right: 37px;
    color: #58656e;
}

.cache-font {
    margin-right: 24px;
    color: #58656e;
}

.unCache-font {
    margin-right: 12px;
    color: #58656e;
}

.dropdown-bg-color {
    background-color: #E9850E !important;
}

@media (min-width: 1435px) {
   .detail-entity-md1-champ {
        width: 8%;
   }

   .detail-entity-md2-champ {
       width: 16%;
   }

   .detail-entity-md3-champ {
        width: 24%;
   }

   .detail-entity-md4-champ {
       width: 32%;
   }

   .detail-entity-md5-champ {
      width: 40%;
   }
}

.entity-dropdown-menu {
    margin-left: -100px;
    width: 220px;
    z-index: 1001;
    overflow: visible;
}

.mapping-icon {
    width: 23px;
    height: 30px;
}

.cache-persitent-container {
    display: inline-block;
    margin-right: 12px;
}

.cache-persitent-dropdown {
    margin-left: -100px;
    width: 200px;
    z-index: 1001;
    overflow: visible;
}

.cache-persitent-li {
    margin-right: 5px;
}

.memory-li {
    margin-right: 10px;
}

.source-refresh-button {
    margin: 0 12px 0 5px;
    color: black;
}

.edit-link-icon {
    margin: 12px 0 0 10px;
}

.carac-modal-container {
    display: flex;
    justify-content: space-between;
}

.actif-label {
    margin: 0 5px 0 0;
}

.links-refresh-label {
    margin-top: 10px;
    color: black;
}

.sort-icon {
    margin-top: 5px;
}

.searche-container {
    display:flex;
}

.searche-label {
    margin:5px 12px 0 0;
}

.links-searche-label {
    margin:0 12px 0 0;
}

.mode-cache-container {
    display: flex;
}

.mode-cache-label {
    margin: -5px 0 0 12px;
}

.type-parameter {
    margin-top: 7px;
}

.data-source-container {
    display: flex;
    align-items: center;
}

.data-source-container i {
    margin-left: 5px;
}

.data-source-sub-container {
    display: flex;
    justify-content: flex-end;
}

.modal-delete-icon {
    margin:5px 0 0 5px !important;
}

.modifier-label {
    margin-left: 7px;
}

.active-checkbox {
    margin-right: 12px !important;
}

.source-filtre {
    margin-right: 24px !important;
}

.searche-icon {
    margin-top: 5px;
    margin-left: 12px;
}