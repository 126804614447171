.message_dialog_container {
    display: grid;
    grid-template-areas: "object"
                         "message";
   grid-template-columns:  1fr;
    grid-template-rows: 0.20fr 0.80fr;
    height: 40vh;
}

.dialog_position_style {
    width: 30%;
    top: 15%;
}

.object_area_container {
   grid-area: object;
}

.object_area_message {
    grid-area: message;
}

.button_container_style {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0.62em
}