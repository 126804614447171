:root {
  --grey-background-color: #EEEEEF;
  --white-background-color: #FFF;
  --red-color : #D71414;
  --dc-primary-color: #666;
  --ag-primary-color: #333;
  --duplicate-header-template-row:  0.12fr;
  --export-header-template-row:  0.03fr;
  --duplicate-project-template-row:  0.88fr;
  --export-project-template-row:  0.88fr;
  --footer-template-row:  0.1fr;
}

.project_to_export {
	  height: 100%;
		display: flex;
		align-items: center;
		font-family:  var(--font-family-title);
		font-weight: 550;
		font-size: 1em;
    color: var(--ag-primary-color);
}

.export_description_ellipsis_text {
    color: var(--text-color);
    word-wrap: anywhere;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
		margin-bottom: 0.625rem;
}

.project_to_export_informations_container {
	padding: 10px;
	gap: 50px;
	background-color: var(--grey-background-color);
	border-radius: 4px;
}

.project_to_export_informations {
	padding: 10px;
}

.project_to_export_information {
	font-family: var(--font-family-title);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: var(--dc-primary-color);
}

.project_to_export_data {
	font-family: var(--font-family-title);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: var(--ag-primary-color);
  width: 90%;
}

.width_170 {
	width: 170px;
}

.height_75 {
  height: 75px;
}

.height_20 {
	height: 20px;
}

.width_90_percent {
	width: 90%;
}

.padding_right_10 {
	padding-right: 10px;
}
