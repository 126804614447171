.checkbox_duplicate_choices_container {
	display: flex;
}

.duplicate_datablock_checkbox_container {
	padding-left: 0.75em;
}

.duplicate_datablock_checkbox {
   font-family:  var(--font-family);
   font-weight: 400;
   font-size: 0.75em;
}

.duplicate_datablock_checkbox label {
   font-family:  inherit;
   font-weight: inherit;
   font-size: inherit;
}

.datablock_duplicate_container {
	display: flex;
}

.datablock_duplicate_root_code {
    width: 100%;
    margin-bottom: 10px;
}

.datablock_duplicate_suffix_form_input {
	width: 75%;
	height: 24px;
}

.datablock_duplicate_suffix_form {
		display: flex;
}

.apply_suffix_button  {
    position: static;
    left: 9.84%;
    right: 9.84%;
    top: 17.5%;
    bottom: 17.5%;
    font-family: var(--font-family-title);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #EEEEEF;
    border-radius : 0.25em;
    background-color: hsl(180deg 100% 20%);
    margin-left : 1rem;
    padding: 0.220em 0.375em;
    border: 1px solid transparent;
}

.datablock_exposed_list_container {
    padding: 0.75em 0.25em;
    list-style-type: none;
    background-color: #EEEEEF;
    border-radius: 0.25em;
		border-radius: 4px;
}

.exposed_list_column {
    display: flex;
    padding-bottom: 0.5em;
    width: 33.33%;
}

.datablock_exposed_list_column_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0.25em;
    font-size: 0.75em;
    font-weight: 600;
    font-family: var(--font-family-title);
    color: var(--ag-primary-color);
}

.exposed_list_body_layout {
    display: flex;
    justify-content: space-between;
    padding-top: 0.25em;
}

.datablock_exposed_list_column_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0.25em;
    font-size: 0.75em;
    font-weight: 500;
    font-family: var(--font-family-title);
    color: var(--ag-primary-color);
}

.datablock_exposed_list_column_body label {
		display: inherit;
    flex-direction: inherit;
    justify-content: inherit;
    align-items: inherit;
    padding-left: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
		white-space: break-spaces;
}

.new_access_point_container {
		background-color: #FFF;
	  display: flex;
	  justify-content: flex-start;
	  position: static;
		left: 0.3125em;
		right: 0.3125em;
		top: 0.3125em;
		bottom: 0.3125em;
		height: 24px;
}

.new_access_point_empty_border {
	border: 1px solid red;
}

.personal_data_notice_contianer {
  display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 8px;
	font-size: 12px;
}

.personal_data_notice {
	color: #333;
	width: 527px;
	height: 18px;
	font-family: var(--font-family-title);
	font-style: normal;
	font-weight: 550;
	margin-bottom: 12px;
}

.personal_data_notice_consent {
	font-family: var(--font-family);
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center
}

.personal_data_notice_consent label {
	font-family: inherit;
	font-style: inherit;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
}

.export_expositions_height {
	height: 530px;
}

.duplicate_expositions_height {
	height: 620px;
}

