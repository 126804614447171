.dc-modal-content.uri-parser-content {
	padding: 2em;
	font-size: 1.25rem;
}


.dc-modal .dc-modal-container.maxsize {
	height: 100vh !important;
	width: 100vw !important;
}


.uri-parser__header-close {
	margin-left: auto;
}

.uri-parser-content {
	--addParam-btn-clr: var(--btn-add);
	--removeParam-btn-hvr-clr: var(--hd-accent);

	display: flex;
	flex-direction: column;
}

.uri-parser-params-container {
	display: grid;
	grid-template-columns: minmax(400px, auto) minmax(400px, auto) 1fr;
	grid-template-areas:
		"paramKey paramValue paramAction";
	grid-column-gap: 1em;
	grid-row-gap: 1.25em;
}

.uri-parser-params__key {
	grid-area: paramKey;
}

.uri-parser-params__value {
	grid-area: paramValue;
}

.uri-parser-params__action-add {
	grid-area: paramAction;
	color: var(--addParam-btn-clr);
}

.uri-parser-params__header {
	margin: 1.25em 0 0.1em 0;
}

.uri-parser-params__entry {
	margin: 0.45em 0 0.25em 0;
}

.uri-parser-params__header-lbl {
	display: flex;
	justify-content: center;
}

.uri-parser-params__action-remove {
	grid-area: paramAction;
}

.uri-parser-params__action-remove-icon:hover {
	color: var(--removeParam-btn-hvr-clr);
}

.uri-parser__uri-label {
	display: flex;
	flex-direction: column;
	width: 18em;
}

.uri-parser__base-path__label {
	font-weight: bold;
}


.uri-parser__full-url {
	margin-top: 2em;
	display: flex;
	flex-direction: column;
}


.uri-parser-actions {
	margin-top: auto;
	margin-left: auto;
	--saveUriParam-btn-clr: var(--gd-primary);
}

.uri-parser-actions__save-btn {
	border-radius : 0.25em;
	background-color: var(--saveUriParam-btn-clr);
	color: white;
}

.uri-parser-actions__save-btn:hover {
	color: white;
}
