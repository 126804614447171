/* connector-form */

.ssh-label {
    margin-top: 4px
}

.security-label {
    padding: 0;
}



.parameter-type-connector-label {
    margin: 5px 0 0 12px;
}

.position-label {
    margin-top: 7px;
}

.center-label{
    text-align: center !important;
}

.password-label {
    padding-left: 48px;
}

.interrogation-label {
    font-weight: 600;
    padding-left: 12px !important;
    padding-right:  0 !important;
    padding-top: 8px;width: 1% !important;
}

.checkbox-position {
    text-align: right
}

.connecter-input {
    margin-bottom: 12px;
}

/* connector-form */

/* aws connector */
.aws-center-label{
    text-align: center !important;
}

.aws-access-key {
    margin: 0 0 10px;
}

.aws-secret-key {
    margin: 0 0 10px;
}

/* aws connector */

/* connector */
.connector-import-container {
    display: flex;
}

.connector-import-label {
    margin: 0 0 0 3px;
}

.new-connecter-button {
    color: #fff;
    border: 0.5px #578445 solid;
    background-color: #578445;
    font-weight: 100;
    border-radius: 6px;
    height: 30px;
    white-space: nowrap;
}

.refrsh-button {
    margin-right: 12px;
    margin-top: 10px;
    color: black;
}

/* .action-delete-button {
    text-align: right;
} */

/* bar-width */
@media (min-width: 2120px) {
    .bar-width {
        width: 83.5% !important;
    }
}

@media (max-width: 1820px) {
    .bar-width {
        width: 83.3% !important;
    }
}

@media (max-width: 1620px) {
    .bar-width {
        width: 83% !important;
    }
}

@media (max-width: 1320px) {
    .bar-width {
        width: 82.5% !important;
    }
}

@media (max-width: 1120px) {
    .bar-width {
        width: 82.2% !important;
    }
}

@media (max-width: 1020px) {
    .bar-width {
        width: 82% !important;
    }
}

@media (max-width: 920px) {
    .bar-width {
        width: 81.5% !important;
    }
}

@media (max-width: 820px) {
    .bar-width {
        width: 81.2% !important;
    }
}

@media (max-width: 720px) {
    .bar-width {
        width: 81% !important;
    }
}

/* bar-width */


/* immitate col-md-? */
@media (min-width: 1385px) {
   .connector-md1-champ {
        width: 8%;
   }

   .connector-md1-label {
        width: 8%;
   }

   .connector-md2-champ {
       width: 16%;
   }

   .connector-md3-champ {
        width: 24%;
   }

   .connector-md2-label {
       width: 16%;
   }

   .connector-md4-champ {
       width: 32%;
   }

   .connector-md8-champ {
          width: 65%;
   }
}

/* Adjust label */
@media (max-width: 1385px) {
   .parameter-type-connector-label {
       margin: 0;

     /*  padding: 0 0 0 28px; */
   }

   .checkbox-position {
      text-align: left !important;
   }

   .security-label {
       padding: 3px 0 0 15px !important;
   }

   .connector-input-margin {
        margin-bottom: 5px;
   }

   .center-label {
      text-align: left !important;
   }
}

/* Adjust label */

/* AWS */
@media (min-width: 1750px) {
   .aws-md2-champ {
       width: 16%;
   }

   .aws-md3-champ {
        width: 24% !important;
   }

   .aws-md2-label {
       width: 16% !important;
   }

   .aws-md4-champ {
       width: 32%;
   }


}

@media (max-width: 1750px){
   .aws-center-label {
      text-align: left !important;
   }

    .aws-parameter-type-connector-label {
              margin: 0 !important;
    }

   .aws-input-margin {
        margin-bottom: 5px;
   }

   .aws-input-margin-top {
        margin-top: 5px;
   }

   .aws-access-key {
       margin: 0;
   }
}

/* AWS */

/* hdfs connector */
.hdfs-host-container {
    display: flex;
}

.hdfs-prefix-host {
    margin: 7px 3px 0 0;
    font-weight: 500;
}

/* hdfs connector */

.dx-datagrid-content .dx-datagrid-table .dx-row>td {
    vertical-align: middle;
}

.dx-lib-align {
    vertical-align: sub;
}

/* toggle .toggle {
    width: 72px !important;
} */

