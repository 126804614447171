.datablock-union_modal {
  width: 80vw;

  --selected-union-clr: var(--dc-secondary);
  --cols-height: 650px;
}

.datablock-union-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 12fr;
  grid-column-gap: 1.25em;
  grid-row-gap: 0.825em;
  height: 100%;
  grid-template-areas:
  "unionAdd ."
  "unionList unionDef";
}

.datablock-union__add {
  grid-area: unionAdd;
  display: grid;
  grid-template-columns: 1fr minmax(150px, auto);
  grid-template-areas:
  "unionAddLabel unionAddButton"
}

.datablock-union__add__label{
  grid-area: unionAddLabel;
  display: flex;
  align-items: center;
}

.datablock-union__add__button {
  grid-area: unionAddButton;
  display: flex;
  margin-left: auto;
  align-items: center;
}

.datablock-union-list__wrapper {
  grid-area: unionList;
	display: flex;
	flex-direction: column;
  gap: 0.25em;
  padding: 0.15em;
  height: 100%;
  overflow-y: scroll;
  border: 0.1em solid var(--dc-secondary);
}

.datablock-union-list__item {
  border-radius: 0.5em;
  min-height: 2.35em;
  display: grid;
  grid-template-columns: 12fr 1fr;
  grid-template-rows: 1fr;
  border: 0.1em solid var(--dc-secondary);
  grid-template-areas:
  "itemLabel itemRemove"
}

.datablock-union-list-item__label {
  grid-area: itemLabel;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.5em;
}

.datablock-union-list-item__remove {
  grid-area: itemRemove;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 0.5em;
}

.datablock-union__definition__wrapper {
  grid-area: unionDef;
  display: grid;
  grid-gap: 1.25em;
  grid-row-gap: 1em;
  grid-template-rows: 1fr 7fr;
  grid-template-columns: 1fr;
  height: var(--cols-height);
  border: 0.1em solid var(--dc-secondary);
  padding: 0.5em;
  grid-template-areas:
  "unionDefMeta"
  "unionDefMapping"
}

.datablock-union__definition__meta {
  grid-area: unionDefMeta;
  display: grid;
  grid-template-columns: minmax(170px, auto) 4fr 30px 1fr;
  grid-template-areas:
   "unionLabel unionField unionField ."
   "entityLabel entityField entitySearch .";
  align-items: center;
  justify-content: center;
  grid-row-gap: 0.75em;
  padding: 0.5em;
}

.datablock-union__label {
  grid-area: unionLabel;
}

.datablock-union__field {
  grid-area: unionField;
}

.datablock-union__entityLabel {
  grid-area: entityLabel;
}

.datablock-union__entityField {
  grid-area: entityField;
}

.datablock-union__entitySearch {
  grid-area: entitySearch;
  margin-left: 1em;
}

.datablock-union__mapping__def {
  grid-area: unionDefMapping;
  display: grid;
  grid-row-gap: 0.5em;
  border: 0.1em solid var(--dc-secondary);
  padding: 0.5em;
  grid-template-areas:
  "unionType"
  "unionMapping"
}

.datablock-union__mapping__type {
  grid-area: unionType;
  display: grid;
  grid-template-columns: minmax(170px, auto) 4fr 30px 1fr;
  grid-template-areas:
    "unionTypeLabel unionTypeField unionTypeField .";
}

.datablock-union__mapping__type__label {
  grid-area: unionTypeLabel;
  display: flex;
}

.datablock-union__mapping__type__field {
  grid-area: unionTypeField;
  display: flex;
}

.union-mapping-wrapper {
    grid-area: unionMapping;
    display: grid;
    grid-gap: 1.25em;
    grid-row-gap: 0.25em;
    grid-template-rows: repeat(auto-fill, 30px);
    grid-template-columns: repeat(2, 1fr);

}

.union-mapping-wrapper-items {
    display: grid;
    grid-row-gap: 0.1em;
    grid-template-rows: repeat(auto-fill, 30px);
    grid-template-columns: 1fr;
    height: 440px;
    overflow-y: auto;
}

.union-mapping-wrapper-item {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
}

.union-type-icon {
    width: 25px !important;
}

.selected-union {
  background-color: var(--selected-union-clr);
}
