.dataview-header{
   height : 70px;
   margin : 3px;
}

.dataview-total-count{
   margin : 3px;
   width: 300px;
   text-align: center
}

.dotted-red-border-div{
   border: 0.5px #f90841 dotted;
}

.dataview-total-count-text {
   font-size: 20px;
   color: #28a745
}

/**** HD Sources *******************/

.hd-steps-c {
    display: flex;
    flex-direction: row;
    margin: 0 5px;
    padding: 5px;
    align-items: center;
}

.hd-step-c {
    border: solid 1px var(--ag-grey3);
    display: flex;
    flex-direction: row;
    margin: 0 5px;
    padding: 5px;
}

.hd-step-c i {
    font-size: 18px;
    color: var(--ag-grey2);
}

.hd-init-step {
    color: var(--light-text-color);
    background-color: var(--ag-grey2);
    padding: 6px;
}

.hd-ops-container span {
    height: 10px;
}

.hd-ops-container i {
    width: 20px;
    padding: 0 5px;
}

.hd-ops-container i:hover {
    filter: brightness(120%);
}

.hd-ops-container small {
    height: 10px;
}


.exec-icon-spacer {
    margin-left: 10px;
}
