.p-export-cancelled {
    color: #333 !important;
}

.p-export-history-height {
    height: 600px;
}

.p-export-history-spinner {
    display: inline-block !important;
}