.graphe-item-icon {
    color: black;
}

.graphe-detail-button {
    margin-right: 5px;
}

.graphe-vue-icon {
    margin-right: 12px;
     color: black;
}

.graphe-info-icon {
    margin-top: 5px;
    color: black;
}

.graphe-filter {
    color: black !important;
}

.graphe-fonction-input {
    padding-left: 0 !important;
}

.graphe-head-modal {
    background: #fff;
    border-bottom: 1px black solid;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 8px;
    padding-top: 8px;
}

.graphe-modal-title {
    margin-left: 12px;
}

.graphe-return-button {
    margin-right: 12px;
}

.graphe-valid-button {
    margin-right: 5px;
}

.graphe-btn-tag {
    color: black !important;
    background-color: #fff;
    border: 1px solid #acb6bd;
}

.graphe-btn-noTag {
    color: #a5a3a4;
    background-color: white;
    border: 1px solid #acb6bd;
}

.selectedFiltre .ui-select-match>span {
    height: 34px;
}

.html-editor-container {
	width: 100%;
	padding: 1px 1px 1px 1px;
}
