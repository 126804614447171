.tag-header-modal {
    background: #fff;
    border-bottom: 1px black solid;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-left: 0;
    padding-right: 0;
}

.tag-icon {
    padding-left: 12px
}

.close-button {
    color: black;
    opacity: 1 !important;
    margin-top: 0 !important;
}

.tag-input {
    margin-bottom: 7px;
}

.close-modal {
    margin: 3px 0 0 5px !important;
}