.tp .vis-left, .ts .vis-left{
	border-right: 1px solid gray!important;
    background: white!important;
}

.tp .vis-right, .ts .vis-right{
	border-left: 0 solid!important;
    background: white!important;
}

.tp .vis-labelset .vis-label, .ts .vis-labelset .vis-label {
}

.tp .vis-inner , .tp .vis-group {
  min-height: 30px !important;
  white-space: nowrap;
  width: 100%
}


.ts .vis-inner , .ts .vis-group {
  min-height: 60px !important;
  white-space: nowrap;
  width: 100%
}


.tp .vis-item{
	border: none!important;
	min-width: 50px!important;
	display: inline-block!important;

	/* background: none!important; */
}

.ts .vis-item{
	border: none!important;
	min-width: 50px!important;
	display: inline-block!important;

	/* min-width: 40px!important; */

	/* background: none!important; */
}

.tp .vis-item .vis-item-content {
	/* background: none!important; */
	padding: 1px;
	border: none!important;
}

.tp .vis-line, .ts .vis-line {
	padding: 0; border: none!important;
	width: 1px!important;
	min-width: 0!important;
}

.tp .vis-item.vis-dot, .ts .vis-item.vis-dot{
	width: auto!important;
	min-width: auto!important;
	font-size: 10px;
}
.ts .vis-item-content span.vis-span{padding: 2px; padding-right: 22px;}
.tp .vis-item-content span.vis-span{padding: 2px; background: #cfcff9; padding-right: 15px;}

.item-clickable{cursor: pointer;}



.modal{
	z-index: 9999;
}

.rotated{
	transform: translateY(500px) rotate(90deg);
}

.rotated .vis-item-content, .rotated .vis-label .vis-inner, .rotated .vis-text span {
	transform: rotate(-90deg)!important;
}

.rotated .vis-text span {
	 display: inline-block;
}

.rotated .vis-label {
	text-align: center;
}

.rotated .vis-label {
	text-align: center;
}

.datepicker {
	z-index: 20000!important;
}

.tp .vis-item-overflow {overflow: visible!important;}
.ts .vis-item-overflow {overflow: visible!important;}

/* Group class */
.tp .category {background-size: 15px 15px; height: 15px; width: 15px; display: inline-block; font-size: 9px!important;}
.ts .category {background-size: 22px 22px; height: 22px; width: 22px; display: inline-block; font-size: 12px!important;}


.truncate{
	width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.el-heading-group .btn-group .btn-link {color: gray!important;}

.tm-refresh-button {
    margin-top: 10px;
    color: black;
}
