.datablock-expose-modal {
  width: 1400px !important;
}

.permission-filter {
  display: grid;
  grid-template-columns: 2fr 4fr;
  grid-template-areas:
      "availableCols filterDef";
  padding: 0.825rem;
  grid-row-gap: 0.825em;
  grid-column-gap: 1.25em;
  height: 535px;
}

.permission-filter__filter-def {
  grid-area: filterDef;
  display: grid;
  grid-template-columns: minmax(150px, auto) 1fr;
  grid-template-areas:
    "filterLabel filterField"
    "descriptionLabel descriptionField"
    "activeLabel activeField"
    "filterValue filterValue";
  align-items: center;
  justify-content: center;
  grid-row-gap: 0.75em;
}

.permission-filter__filter-def__filter-label {
  grid-area: filterLabel
}

.permission-filter__filter-def__filter-field {
  grid-area: filterField
}

.permission-filter__filter-def__description-label {
  grid-area: descriptionLabel
}

.permission-filter__filter-def__descriptionField {
  grid-area: descriptionField
}

.permission-filter__filter-def__active-label {
  grid-area: activeLabel
}

.permission-filter__filter-def__active-field {
  grid-area: activeField;
	display: flex;
	justify-content: space-between;
}

.permission-filter__filter-def__filter-value {
  grid-area: filterValue;
}

.permission-filter__available-cols {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(30px, auto) 1fr;
  grid-area: availableCols;
  grid-row-gap: 0.825em;
  grid-template-areas:
    "availableColsSearch ."
    "availableColsItems availableColsItems";
}

.permission-filter__available-cols__search {
  grid-area: availableColsSearch;
}

.permission-filter__available-cols__items {
  grid-area: availableColsItems;
  grid-row-gap: 0;
  grid-template-columns: 1fr;
  grid-template-areas: "item";
  overflow-y: auto;
  max-height: 472px;
}

.permission-filter__available-cols__items__item {
  grid-area: item;
  display: grid;
  border-top: 1px solid #ced4d8;
  grid-template-columns: minmax(40px, auto) 1fr;
  grid-template-areas: "itemType itemLabel";
  height: 34px;
}

.permission-filter__available-cols__items__item:first-child {
   border-top: none;
}

.permission-filter__available-cols__items__item__item-type {
  display:flex;
}

.permission-filter__available-cols__items__item__item-label {
  display:flex;
  align-items: center;
}

#filter-perm-active {
  margin: 0;
}

.permission-filter-apply {
  display: grid;
  grid-template-columns: 2fr 4fr;
  grid-template-rows: minmax(40px, auto) 1fr;
  grid-template-areas:
      "searchBox ."
      "usersWrapper usersWrapper";
  padding: 0.825rem;
  grid-row-gap: 0.825em;
  grid-column-gap: 1.25em;
  height: 500px;
}

.permission-filter_users-wrapper {
  grid-area: usersWrapper;
   border-top: solid 2px var(--dc-primary);
}

.permission-filter_users-wrapper:first-child {
   border-top: none;
}

.permission-filter_users {
  grid-area: users;
  display: grid;
      padding: 0.3em;
  grid-template-columns: 1fr minmax(50px, auto);
  grid-auto-rows: 50px;
  border-bottom: 1px solid #ced4d8;
  justify-content: center;
  align-items: center;
  grid-template-areas:
    "user user actions";
}


.permission-filter-apply_search-box {
  grid-area: searchBox;
  display: flex;
}

.permission-filter-apply_user {
  grid-area: user;
  display: flex;
}

.permission-filter-apply_action {
  grid-area: actions;
  display: flex;
}

.apply-users__member {
    display: flex;
    align-content: flex-start;
    align-items: baseline;
}

.apply-users__member__roles {
    display: flex;
    justify-content: flex-start;
    width: 4em;
}

.apply-users__member__roles > i {
    margin-left: 0.2em;
    margin-right: 0.2em;
}

.apply-users__member__name {
    margin-left: 0.75em;
}

.element_name_font {
	font-weight: 500;
	font-size: 0.9em;
	padding: 4px 0px 4px 0px;
	font-family: var(--font-family);
}

.popup_title {
	font-weight: 600;
	font-size: 1.2em;
	font-family: var(--font-family-title);
}

.popup_subtitle {
	font-weight: 550;
	font-size: 1em;
	padding-top: 4px;
	font-family: var(--font-family-title);
}

