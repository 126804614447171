.confetti {
  display: block;
  margin: 2rem auto;
  width: 540px;
  height: 540px;
}

.first-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.first-component > span {
  font-weight: bold;
  font-size: 36px;
  color: var(--gd-primary);
}
