.header-flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.top-header {
    height: 45px;
    font-family: var(--font-family);
    z-index: 1004;
    background: var(--ag-grey1);
    position: fixed;
    width: 100%;
    top: 0;
}

.header-icon {
    cursor: pointer;
    color: white;
}

.dpd-header > li {
    cursor: pointer;
    font-weight: 400;
    line-height: 2.2;
    padding: 3px 15px 3px 0;
    width: 250px;
}


.nav-font-icon {
    width: 36px;
    color: #fff;
    margin-left: 10px;
}

.nav-font-parameter {
    margin: 0 10px;
    color: #fff;
}

.active-icon-graph-admin {
    border-radius: 50%;
    border: solid 2px var(--gd-accent);
}

.active-icon-graph {
    border-radius: 50%;
    border: solid 2px var(--hd-accent);
}

.active-icon-traces-admin-hover :hover {
    border-radius: 50%;
    border: solid 2px var(--gd-accent);
}

.active-icon-traces {
    border-radius: 50%;
    border: solid 2px var(--hd-accent);
}

.active-icon-traces-admin {
    border-radius: 50%;
    border: solid 2px var(--gd-accent);
}

.active-icon-graph-hover:hover {
    color: var(--hd-accent) !important;
}

.active-icon-plus-hover:hover {
    background-color: var(--hd-accent);
    border-radius: 50%;
    border: solid 1px var(--hd-accent);
}

.active-icon-traces-hover:hover {
    background-color: var(--hd-accent);
    border-radius: 50%;
    border: solid 1px var(--hd-accent);
}

.active-icon-graph-admin-hover:hover {
    color: var(--gd-accent) !important
}

.active-test:hover {
    background-color: var(--gd-accent) !important;
}

.active-icon-plus-admin-hover:hover {
    background-color: var(--gd-accent) !important;
    border-radius: 50%;
    border: solid 1px var(--gd-accent);
}

.active-icon-traces-admin-hover:hover {
    background-color: var(--gd-accent) !important;
    border-radius: 50%;
    border: solid 1px var(--gd-accent);
}

/* .dpd-img-hd :hover {
    background-color: var(--hd-accent) !important;
}

.dpd-img-gd :hover {
    background-color: var(--gd-accent) !important;
} */

/* .list-header:hover .list-header-child {
    background-color: #232323 !important;
}

.list-header-admin:hover .list-header-child {
    background-color: #243d65 !important;
} */

.header-element-gd :hover, .list-header-admin :focus {
    color: var(--gd-accent) !important;
}

.header-img-gd > img:hover {
    background-color: var(--gd-accent) !important;
}

.header-img-hd >img:hover {
    background-color: var(--hd-accent) !important;
}


.header-element :hover, .list-header :focus {
    color: var(--hd-accent) !important;
}

.contexte-list-admin-hover :hover .context-child {
    color: var(--gd-accent) !important
}

.contexte-list-hover :hover .context-child {
    color: var(--hd-accent) !important
}

.contexte-list-hover :hover .context-child {
    color: var(--hd-accent) !important
}

.context-element {
    height: 36px;
    padding: 0;
}

.context-element a{
    text-decoration: none;
}

.context-element a:hover{
    text-decoration: underline;
}

.context-jobs {
    padding: 0 5px;
    color: var(--job-green);
    max-width: 55px;
}

.context-label {
    display: inline-flex;
    align-items: center;
    padding: 0 0 0 5px;
}

.context-ressources {
    margin: 0;
    align-items: center;
    padding: 0 5px;
    color: #fff;
}

.context-current-job {
    margin: 0;
    padding: 0;
    color: var(--job-green);
    overflow: hidden;
    align-items: center;
}

.hdh-spark-label {
    margin: 14px 5px 0 0;
}

.hdh-spark-container {
    display: flex;
    flex-wrap: nowrap;
}

.hdh-spark-flesh {
    margin-top: 8px;
}

.hdh-spark-dropdown {
    margin-left: -360px;
    width: 550px;
}

/* .context-list {
    width: 380px !important;
} */

/* .context-list a {
    height: 34px;
} */

.context-list-parent {
    height: 30px;
}

/*
context-list-parent.hd-accent-hover a:hover{
    color: var(--hd-accent) !important;
}

context-list-parent.gd-accent-hover a:hover{
    color: var(--gd-accent) !important;
}
*/

.context-link:focus {
    background-color: inherit !important;
}

.context-link:hover {
    text-decoration: none !important;
    color: white !important;
}

.context-link-child span:hover {
    color: #232323 !important;
}

.context-link-child-admin span:hover {
    color: #243d65 !important;
}

.notif-icon {
    color: white;
    font-size: 24px;
    margin: 15px 10px 0 5px;
}

.jobs-icon {
    font-size: 24px !important;
    margin: 16px 10px 0 0 !important;
}

.spinner-icon {
    color: white;
    margin-top: 18px;
    margin-right: 10px;
    font-weight: bolder;
    font-size: 25px;
}

.spinner-icon-export {
    color: white;
    margin-right: 10px;
    font-weight: bolder;
    font-size: 25px;
}

.spinner-icon-black {
    color: black;
    font-weight: bolder;
    font-size: 25px;
}

.element-hidden {
    visibility: hidden;
}

.current-job {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.2em;
    white-space: nowrap;
    vertical-align: middle;
    line-height: normal;
}

.current-job-li {
    width: 130px;
}

.frame-status {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}


.flesh-dropdown {
    margin-left: -145px;
    margin-top: -3px;
    width: 200px;
}



.top-bar-list {
    height: 45px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.right-flex-container {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.establishment-name-c {
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
}

.dc-project-header {
    color: var(--light-text-color);
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    align-self: center;
}

.dc-project-name {
    width: 300px;
}

.dc-module-name {
    width: 150px;
}

.project-title {
    display: flex;
    height: inherit;
    justify-content: center;
}



.left-items-bar {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   height: 45px;
}

.left-items-bar i {
   padding-left: 15px;
}

@media (max-width: 1560px) {
    .left-items-container {
        width: 65%;
    }

    .left-items-bar {
       width: 40%;
    }

    .dc-logo {
        display:none;
    }
}

@media (max-width: 960px) {
    .logo-container {
        display: none;
    }

    .left-items-container {
        width: 40%;
    }

    .left-items-bar {
        width: 40%;
    }

    .dc-project-header {
        width: 120px;
    }

     .right-items-container {
        width: 60%;
     }
}


.main-layout__header {
	display: grid;
	grid-template-columns: minmax(5rem, 10rem) 1fr 1fr minmax(500px, 600px);
	grid-template-areas: "moduleSwitcher quickActions moduleProject otherActions";
	color: white;
}

.main-layout__header__moduleSwitcher {
	grid-area: moduleSwitcher;
	display: flex;
	align-items: center;
}

.main-layout__header__quickActions {
	grid-area: quickActions;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

.main-layout__header__quickActions__establishment {
	display: flex;
	align-items: center;
	margin: 0 1em 0 0;
}

.main-layout__header__quickActions__establishment-name {
	font-weight: 500;
	color: #fff;
	padding: 0;
	font-size: large;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	vertical-align: middle;
	line-height: normal;
}

.main-layout__header__quickActions__establishment-logo {
	width: 3em;
	object-fit: contain;
	margin-right: 0.55em;
}

.main-layout__header__quickActions__actions {
	display: flex;
	align-items: baseline;
	gap: 1.25em;
	border-right: solid 0.055em white;
	border-left: solid 0.055em white;
}

.main-layout__header__quickActions__action {
	padding: 0 0.35em;
	cursor: pointer;
	color: white;
}

.main-layout__header__moduleProject {
	grid-area: moduleProject;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--light-text-color);
	font-size: 18px;
	font-weight: bold;
}

.main-layout__header__moduleProject__separator {
	margin: 0 0.75em;
}

.main-layout__header__other {
	grid-area: otherActions;
	display: flex;
	justify-content: flex-end;
}
