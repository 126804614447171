.datablockLink_modal {
  width: 90vw;

  --border-clr: #d7d7d7;
  --valid-link-clr: #000;
  --invalid-link-clr: var(--alert-color);
  --link-left-panel-width: 300px;
  --mapping-disclaimer-clr: var(--dc-secondary);
  --selected-link-clr: var(--dc-secondary);
  --btn-clr: var(--btn-add);
}

.datablock-ng-container {
  display: contents;
}

.datablockLink {
  display: grid;
  grid-template-columns: var(--link-left-panel-width) 1fr;
  grid-template-areas:
      "header header"
      "addLink linkValidationError"
      "linksList linkConfig";
  padding: 1rem;
  grid-row-gap: 0.825em;
  grid-column-gap: 1.25em;
}

.datablockLink__header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.datablockLink__header-btns {
  margin-left: auto;
}

.datablockLink__addLink {
  grid-area: addLink;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datablockLink__linkValidationError {
  grid-area: linkValidationError;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datablockLink__linksList {
  grid-area: linksList;
  width: var(--link-left-panel-width);
  display: flex;
  flex-direction: column;
  border: 0.1em solid var(--border-clr);
  border-radius: 0.5em;
}

.datablockLink__linksList__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1em solid var(--border-clr);
  padding: 0.25em 0.55em;
}

.datablockLink__linksList__link:last-child {
  border-bottom: 0;
}

.datablockLink__linksList__link__ctrls {
  display: flex;
  gap: 0.25em;
}

.datablockLink__linksList__link--valid {
  color: var(--valid-link-clr);
}

.datablockLink__linksList__link--selected {
  background-color: var(--selected-link-clr);
  border-radius: 0.5em;
  font-weight: bold;
}

.datablockLink__linksList__link--not-valid {
  color: var(--invalid-link-clr);
}

.datablockLink__linkConfig {
  grid-area: linkConfig;
  display: grid;
  grid-template-columns: minmax(250px, auto) 1fr minmax(250px, 300px);
  grid-template-areas:
      "linkLabel linkField ."
      "entityLabel entityField entitySearch"
      "entityCaracs entityCaracs entityCaracs"
      "linkMapping linkMapping linkMapping"
      "disclaimer disclaimer disclaimer";
  align-items: center;
  justify-content: center;
  grid-row-gap: 0.75em;
}

.datablockLink__linkConfig--not-selected {
  display: flex;
  justify-content: center;
  align-items: center;
}

.datablockLink__linkLabel {
  grid-area: linkLabel;
}

.datablockLink__linkField {
  grid-area: linkField;
}

.datablockLink__entityLabel {
  grid-area: entityLabel;
}

.datablockLink__entityField {
  grid-area: entityField;
}

.datablockLink__entitySearch {
  grid-area: entitySearch;
  margin-left: 1em;
}

.datablockLink__disclaimer {
  grid-area: disclaimer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--mapping-disclaimer-clr);
}

.datablockLink__entityCaracs {
  grid-area: entityCaracs;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: 0.1em solid var(--border-clr);
  border-radius: 0.5em;
  padding: 0.25em 0.55em;
  margin: 0.825em 0;
}

.datablockLink__entityCaracs--error {
  color: var(--invalid-link-clr);
  margin: 1em;
}

.datablockLink__entityCaracs__selectedCarac {
  display: grid;
  grid-template-columns: repeat(2, minmax(15px, auto)) 1fr;
  grid-template-areas:
      "caracCheckBox caracType caracNameLabel caracNameLabel";
}

.datablockLink__entityCaracs__allCaracsCheckBox {
  grid-area: allCaracsCheckBox;
}

.datablockLink__entityCaracs__caracCheckBox {
  grid-area: caracCheckBox;
}

.datablockLink__entityCaracs__caracType {
  grid-area: caracType;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}

.datablockLink__entityCaracs__caracNameLabel {
  grid-area: caracNameLabel;
  display: flex;
  margin-left: 0.25em;
}


.datablockLink__linkMapping {
  grid-area: linkMapping;
  display: grid;
  grid-template-columns: 1fr minmax(250px, 300px) 1fr;
  grid-template-areas:
    "jointTypeLabel jointTypeSelect addNewMapping"
    "sourceTargetMapping sourceTargetMapping sourceTargetMapping";
  align-items: center;
  justify-content: center;
  grid-column-gap: 0.35em;
  grid-row-gap: 1.15em;
  margin: 1.2em 0 1.25em;
}

.datablockLink__jointTypeLabel {
  grid-area: jointTypeLabel;
  display: flex;
  align-items: center;
}

.datablockLink__jointTypeSelect {
  grid-area: jointTypeSelect;
}

.datablockLink__addNewMapping {
  grid-area: addNewMapping;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.datablockLink__sourceTargetMapping {
  grid-area: sourceTargetMapping;
  display: flex;
  flex-direction: column;
}

.datablockLink__source {
  display: flex;
  justify-content: center;
  align-items: center;
}

.datablockLink__operator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.datablockLink__target {
  display: flex;
  justify-content: center;
  align-items: center;
}

.datablockLink__mappings {
  display: grid;
  grid-template-columns:
      1fr minmax(150px, auto)  1fr minmax(20px, auto);
  grid-template-areas:
    "sourceMapping operatorType targetMapping removeMapping";
  align-items: center;
  justify-content: center;
  grid-column-gap: 0.75em;
  grid-row-gap: 0.35em;
  margin-bottom: 0.35em;
}

.datablockLink__sourceMapping {
  grid-area: sourceMapping;
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
}

.datablockLink__sourceMapping__manual {
  padding: 0 1em;
}

.datablockLink__sourceMapping__carac {
  flex-grow: 2;
}

.datablockLink__sourceMapping__carac > input {
  width: 100%;
  padding: 0.15em 1.25em;
}

.datablockLink__operatorType {
  grid-area: operatorType;
  display: flex;
  justify-content: center;
}

.datablockLink__targetMapping {
  grid-area: targetMapping;
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
}

.datablockLink__targetMapping__manual {
  padding: 0 1em;
}

.datablockLink__targetMapping__carac {
  flex-grow: 2;
}

.datablockLink__targetMapping__carac > input {
  width: 100%;
  padding: 0.15em 1.25em;
}

.datablockLink__removeMapping {
  grid-area: removeMapping;
  display: flex;
  justify-content: flex-start;
}

.datablockLink__new-link, .datablockLink__new-mapping-criteria {
  color: #fff;
  border: 0.5px var(--btn-clr) solid;
  background-color: var(--btn-clr);
  font-weight: 100;
  border-radius: 6px;
  height: 30px;
  white-space: nowrap;
}
