.duplicate_element_type {
   font-family:  var(--font-family-title);
   font-weight: 500;
   font-size: 1em;
   color: var(--ag-primary-color);
   padding-bottom: 0.75em;
}

.element_to_duplicate_text_style {
    font-weight: 400;
    font-size: 0.75em;
    font-family: var(--font-family);
}

.elements_counter_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 1.2em;
    width: 2em;
    border-radius: 2.5em;
    padding: 0.188em 0.313em;
    background-color: #F1F1F0;
}

.padding-right-24 {
	padding-right: 24px;
}

.imported_user_label_ellipsis_text {
    word-wrap: anywhere;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.element_counter {
    display: flex;
    justify-content: center;
    width: 0.188em;
    font-size: 0.7em;
    font-weight: 275;
    font-family: var(--font-family-title);
    color: var(--ag-primary-color);
}

.element_to_duplicate_container {
    display: flex;
}

.duplicate_type_elements_link_text {
   display: flex;
   align-items: center;
   font-size: 0.688em;
   font-weight: 400;
   font-family: var(--font-family);
   background-color: var(--grey-background-color);
}

.data_to_duplicate_title_style {
   font-size: 0.75em;
   font-weight: 500;
   font-family: var(--font-family);
   color: var(--ag-primary-color);
   padding-bottom: 0.75em;
}

.import-project-data {
	padding-bottom: 12px
}

.import-project-data-label {
	font-family: var(--font-family-title);
	font-weight: 550;
	font-size: 14px
}

.import-project__mandatory-context {
	font-family: var(--font-family-title);
	font-weight: 400;
	font-size: 12px
}

.import-project_no-data {
	padding: 12px 0 12px 0;
}

.import-project_no-data-label {
	font-family: var(--font-family);
	font-weight: 500;
	font-style: italic;
	font-size: 13px;
}


.export-project-import-groups {
	font-family: var(--font-family-title);
	font-weight: 550;
	font-size: 14px;
	padding-top: 12px;
}
