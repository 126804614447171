
.charttype-container {
    display: flex;
    flex-direction: row;
    width: 85%;
    flex-wrap: wrap;

}

.charttype-c {
    display: flex;
    flex-direction: row;
    margin: 20px;
    border: 1px solid var(--ag-grey1);
    border-radius: 5px;
    text-align-last: center;
}

.charttype-c a {
    margin: 15px;
    padding: 15px;
    text-decoration: none;
    color: var(--ag-grey2);

}

.charttype-c a:hover {
    background-color: var(--ag-grey3);
    border-radius: 5px;
    filter: invert(1);
}

.charttype-title {
    display: block;
    text-align: center;
}

.serie-conf {
    background-color: var(--ag-grey4);
    margin: 5px;
    padding: 5px;
}

.column-name--is-edited {
	color: var(--warning-color);
}
