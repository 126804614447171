:root {
  --show-all-gd-container-width: 56.5%;
  --show-all-hd-container-width: 85%;
  --grey-background-color: #EEEEEF;
  --gd-color: #00B0B0;
  --hd-color: #EE4A4A;
  --hover-color-hd-stats: #C9302C;
  --state-name-gd-color: #BFEBEB;
}

.card_container {
  display: grid;
  grid-template-areas:
          "area1 area1"
          "area2 area2"
          "area4 area4";
  grid-column-gap: 24px;
  grid-template-columns: auto auto;
  height: 100%;
}

.description_ellipsis_text {
    color: var(--text-color);
    word-wrap: anywhere;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 0.625rem 0 0.625rem 0.625rem;
}

.card_one {
  grid-area : area1;
  background-color: var(--ag-grey4) !important;
  border-radius: 4px;
}

.card_two {
  display: grid;
  grid-area : area2;
  grid-template-areas : "gdIcon gdIcon"
                        "gdStats .";
  grid-template-columns: var(--show-all-gd-container-width) 1fr;
  grid-row-gap: 1rem;
}

.gd_icon_card {
  grid-area: gdIcon;
  padding-top: 0.8rem;
}

.gd_stats_card {
  display: flex;
  grid-area: gdStats;
  padding: 0 0.625rem 0 0;
  justify-content: space-between;
}

.card_three {
  display: grid;
  grid-area: area3;
  grid-template-areas : "hdIcon hdIcon"
                        "hdStats .";
  grid-template-columns: var(--show-all-hd-container-width) 1fr;
  grid-row-gap: 1rem;
}

.hd_stats_card {
  display: flex;
  grid-area: hdStats;
  padding: 0 10px 0 0;
  justify-content: space-between;
}

.hd_icon_card {
   grid-area: hdIcon;
  padding-top: 0.8rem;
}

.card_four {
  grid-area : area4;
  padding-top: 0.4em;
}

.count_value_stats {
  font-size: 1rem;
  font-weight: lighter;
}

.pj-public {
    color: var(--green-dark);
}

.pj-private {
    color: var(--red-dark);
}

.modification-style {
    font-family: var(--font-family-title);
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 0.4em;
}

.title_and_icon_container {
    display: flex;
}

.h1_title_project_style {
    padding-left: 10px;
    font-size: 1.25rem;
}

.element_stats_grid_container {
    display: grid;
    grid-template-areas: "icon elementName"
                         ".    countValue";
    grid-template-columns: 0.3fr auto 0.3fr 0.7fr;
    grid-row-gap: 0.313rem;
    border: 0.125rem solid #eee;
    border-radius: 0.25rem;
    padding: 0.625rem;
    width: 13.125rem;
    height: 4.375rem;
}

.stats_list_element {
    display: flex;
}

.element_stats_grid_icon_area {
    grid-area: icon;
}

.project_information_container {
    padding: 10px 10px 0;
    display : flex;
		justify-content: flex-start;
    align-items: center;
}

.settings_icon_style {
    margin-left : auto;
}

.last_activities_container_layout {
    height: 540px;
    width: 100%;
}

.show_all_container {
    display: flex;
    justify-content: flex-end;
    width: 210px;
}

.informative_title_style {
    font-size: 0.9rem;
    font-weight: 300;
    font-style: italic;
}

.element_stats_grid_element_name_area {
    grid-area: elementName;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: var(--font-family-title);
    font-size: 1rem;
    padding-left: 5px;
}

.element_stats_grid_count_value_area {
    grid-area: countValue;
    padding-left: 0.313rem;
}

.libIconContainer {
    display: grid;
    grid-template-areas: "libArea1 libArea2";
    grid-column-gap: 2px;
    grid-template-columns: 20%  80%;

}

.grey_background {
  background-color: var(--grey-background-color);
}

.libArea1 {
  grid-area: libArea1;
  display: flex;
  justify-content: center;
}

.libArea2 {
  grid-area: libArea2;
}

.gd-color {
    color: var(--gd-color);
}

.hd-color {
    color : var(--hd-color);
}


.align-list {
  display: flex;
}

.hover_gd_stats > li:hover {
	color: var(--gd-color);
}

.hover_hd_stats > li:hover {
	color: var(--hover-color-hd-stats);
}

.gd_state_name {
   font-family: var(--font-family-title);
   display:flex;
   justify-content: center;
   width: 6.875em;
}

.hd_state_name {
    font-family: var(--font-family-title);
    display:flex;
    justify-content: center;
    width: 6.875em;
 }

 .action_name_element_style {
    font-size: 11px;
    width: 90px;
    border-radius: 8px;
 }

.gd_new_element_color {
    background-color: #80D8D8;
}

.hd_new_element_color {
    background-color: #F7A5A5;
}

.gd_updated_element_color {
    background-color: #BFEBEB;
}

.hd_updated_element_color {
    background-color: #FAD1D1;
}

.gd_deleted_element_color {
    background-color: #EEEEEF;
;
}

.hd_deleted_element_color {
    background-color: #FEEDED;
}

.commmon_gd_hd_element_color {
    background-color:  #EEEEEF;
    border-radius: 0.5em;
}

.project-stats-element-tags {
   display : flex;
   padding: 0.4em 0 0.8em 0.5em;
}

.project-stats-element-tag {
   display : flex;
   justify-content: space-around;
}

.action_name_column_style {
    display:flex;
    justify-content: space-between;
    width: 5.625em;
    align-items: center;
    font-family: var(--font-family-title);
    font-weight: 400;
    font-size: 15px;
}

.large_width {
   width: 7.5em;
}

.persist_width {
    width: 8em;
}

.extract_width {
    width: 7em
}

.depersist_action_width {
   width: 8.438em;
}

.date_column_layout {
    display: flex;
    justify-content: space-around;
}
