.search-bar{
    border: solid 1px #ebf0fa;
    margin-left: 1px;
    margin-right: 3px;
}

.search-item-icon {
    color: black;
}

.search-ignorecase-checkbox {
    margin-left: 10px !important;
}

.search-parameter-table {
    margin-left: 15px;
}

.search-value-label {
    margin-right: 5px;
}