.dashboard-grid-cell-content {
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
}

.dashboard-grid-cell__info {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.35rem;
}
