
* {
    margin: 0px;
    /*padding: 0px;*/
}

html {
    height: 100%;
}

body {
    height: 100%;
    min-width: 600px;
    font-family: var(--font-family);
}

header {
    min-width: 600px;
}

.container {
    width: 85%;
    padding: 0px;
}

.title {
    margin-left: 0px
}

h1 {
    padding-bottom: 0 !important;
    margin-bottom: 20px;
    color: var(--ag-grey1);
}

h1 > span.subhead {
    font-size: 17px;
    color: var(--ag-grey1);
    display: block
}

h1 > span.subhead-min {
    font-size: 16px;
    color: var(--ag-grey1);
    display: block
}


.footer, .push {
    height: 20px
}

.copy-right {
    margin: 12px 0 0 80px
}

.links {
    margin: 12px 80px 0 0;
    float: right;
}

.footer {
    position: fixed;
    bottom: 0;
    margin-top: 20px;
    margin-left: 70px;
    background-color: #fff;
    width: 100%;
    border-top: 1px solid #c9cfd3;
    font-family: Arial, sans-serif !important;
    font-size: 12px;
    color: #575656;
    height: 35px;
    z-index: 1002;
}

.footer > .container {
    margin-top: 20px
}

.links > a {
    margin-right: 20px;
    color: #7c7b7c;
}

.login {
    padding-top: 80px !important
}

@media ( min-width: 768px) {
    .login > .container {
        max-width: 660px
    }
}

.login-forgot-pwd {
    line-height: 20px;
    float: right !important
}

.icon-menu {
    font-size: 17px
}

.signup-container {
    max-width: 375px;
    margin: 0 auto
}

.signup-header {
    padding: 15px 15px;
    line-height: 15px;
    height: 45px;
    font-size: 17px;
    background-color: #f4f6f7;
    border-bottom: 1px solid #c9cfd3;
    margin-bottom: 40px
}

.signup-success-mess {
    font-size: 15px
}

.clickable {
    cursor: pointer;
}

.required:after {
    content: "*";
    color: red;
    margin-left: 5px;
}

.btn-del {
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 1px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 18px
}

.width_25 {
    width: 25px;
}

.btn-modif {
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 1px 0;
    font-size: 12px;
    line-height: 1.428571429;
}

.validation-error {
    color: red;
}

.bottom-space-0 {
    margin-bottom: 0px;
}

.bottom-space-0-f {
    margin-bottom: 0px !important;
}

.bottom-space-2 {
    margin-bottom: 2px;
}
.bottom-space-5 {
    margin-bottom: 5px;
}

.bottom-space-10 {
    margin-bottom: 10px;
}

.bottom-space-15 {
    margin-bottom: 15px;
}

.bottom-space-20 {
    margin-bottom: 20px;
}

.bottom-space-30 {
    margin-bottom: 30px;
}

.bottom-space-40 {
    margin-bottom: 40px;
}

.bottom-space-80 {
    margin-bottom: 80px;
}

.top-space-0 {
    margin-top: 0px;
}

.top-space-5 {
    margin-top: 5px;
}

.top-space-6 {
    margin-top: 6px;
}

.top-space-3 {
    margin-top: 3px;
}

.top-space-2 {
    margin-top: 3px;
}

.top-space-8 {
    margin-top: 8px;
}

.top-space-5-force {
    margin-top: 5px !important;
}

.top-space-7 {
    margin-top: 7px;
}

.modal_config_tooltip_height {
    height: 31.25rem;
    overflow-y: auto;
    font-size: 15px;
}

.space_between_content {
    display: flex ;
    justify-content: space-between;
    padding: 0em 0em 0.625em 0em;
}

.flex_space_between_content {
    display: flex ;
    justify-content: space-between;
}

.flex_align_items {
    display: flex ;
    align-items: center;
}
.flex_end_content {
    display: flex ;
    justify-content: flex-end;
}

.top-space-10 {
    margin-top: 10px
}

.top-space-12 {
    margin-top: 12px
}

.top-space-10-force {
    margin-top: 10px !important;
}


.top-space-15 {
    margin-top: 15px;
}

.top-space-20 {
    margin-top: 20px;
}

.top-space-30 {
    margin-top: 30px !important;
}

.top-space-40 {
    margin-top: 40px !important;
}

.top-space-50 {
    margin-top: 50px;
}

.top-space-60 {
    margin-top: 60px;
}

.right-space-3 {
    margin-right: 3px;
}

.right-space-5 {
    margin-right: 5px;
}

.right-space-15 {
    margin-right: 15px;
}

.right-space-10 {
    margin-right: 10px;
}

.right-space-10-f {
    margin-right: 10px !important;
}

.right-space-7 {
    margin-right: 7px;
}


.right-space-20 {
    margin-right: 20px;
}

.right-space-30 {
    margin-right: 30px;
}

.right-space-40 {
    margin-right: 40px;
}
.right-space-120 {
    margin-right: 120px;
}

.left-space-0 {
    margin-left: 0px;
}

.left-space-5 {
    margin-left: 5px;
}

.left-space-10 {
    margin-left: 10px;
}

.left-space-12 {
    margin-left: 12px;
}

.left-space-15 {
    margin-left: 15px;
}

.left-space-20 {
    margin-left: 20px !important;
}

.left-space-30 {
    margin-left: 30px !important;
}

.left-space-40 {
    margin-left: 40px !important;
}

.left-space-50 {
    margin-left: 50px
}

.left-space-60 {
    margin-left: 60px
}

.left-space-70 {
    margin-left: 70px
}

.left-space-80 {
    margin-left: 80px
}
.left-space-120 {
     margin-left: 120px
 }

.left-space-185 {
     margin-left: 185px
}

fieldset.default {
    border: 1px solid #c9cfd3 !important;
    border-radius: 3px;
    padding: 0 1.3em .5em 1.3em !important;
    margin: 0 30px 1.5em 30px !important;
    -webkit-box-shadow: 0 0 0 0 #000;
    box-shadow: 0 0 0 0 #000
}

#grammarFeatures {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin-right: 10px;
  margin-top: 5px;
}

legend.default {
    font-size: 1em !important;
    font-weight: 700 !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none
}


.scroller {
    overflow-y : auto;
    max-height : 500px;
 }

.input-group-addon {
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: .6em;
    color: #212121;
    text-align: center;
    background-color: #f4f6f7;
    border: 1px solid #acb6bd;
    border-radius: 4px;
}


/*
.page-title {
    margin-top: 15px;
    color: #363636
}
*/

.toast-success {
    background-color: #00c853;
}

.toast-error {
    background-color: #ff1744
}

.toast-info {
    background-color: #29b6f6
}

.toast-wait {
    background-color: #2f96b4
}

.toast-warning {
    background-color: #ffc400
}

.btn-lightest {
    color: #424242;
    background-color: #fafbfb;
    border-color: #c2c9ce
}

.btn-lightest.active, .btn-lightest:active, .btn-lightest:focus,
.btn-lightest:hover, .open .dropdown-toggle.btn-lightest {
    color: #424242;
    background-color: #f2f3f4;
    border-color: #b9c1c7
}

.btn-lightest.active, .btn-lightest:active, .open .dropdown-toggle.btn-lightest {
    background-image: none
}

.btn-lightest.disabled, .btn-lightest.disabled.active, .btn-lightest.disabled:active,
.btn-lightest.disabled:focus, .btn-lightest.disabled:hover,
.btn-lightest[disabled], .btn-lightest[disabled].active, .btn-lightest[disabled]:active,
.btn-lightest[disabled]:focus, .btn-lightest[disabled]:hover, fieldset[disabled] .btn-lightest,
fieldset[disabled] .btn-lightest.active, fieldset[disabled] .btn-lightest:active,
fieldset[disabled] .btn-lightest:focus, fieldset[disabled] .btn-lightest:hover {
    background-color: #fafbfb;
    border-color: #c2c9ce
}

.text-gray-dark {
    color: #58656e
}

.search-panel {
    background-color: #EEE;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border: 1px solid #DDD;
    border-radius: 4px
}

.text-light-green {
    color: #00d759
}

.text-light-red {
    color: #ff2650
}

.text-light-blue {
    color: #38bbf7
}

.text-light-yellow {
    color: #ffc505
}

.font-size-l {
    font-size: 15px
}

.ui-select-match > span.btn-default:hover {
    color: #424242;
    background-color: #f2f3f4;
    border-color: #b9c1c7
}

input[type=checkbox], input[type=radio], select {
    cursor: pointer
}

.modal {
    overflow-y: auto
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

.editable {
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px dashed
}

.wrapper {
    height: auto !important;
    margin: 0 auto -34px;
    min-height: 95.3%;
    width: 100%
}

.full-page-wrapper .inner-page {
    margin-bottom: 50px;
    padding-top: 2%
}

@media screen and (max-width: 768px) {
    .full-page-wrapper {
        margin-top: 0;
        padding-top: 5em;
        position: initial
    }

    .full-page-wrapper .inner-page {
        padding: 0 1em;
        position: relative
    }
}

@media screen and (max-width: 480px) {
    .full-page-wrapper {
        padding-top: 0
    }

    .full-page-wrapper .inner-page {
        padding: 5em 1em
    }
}

/*************** LOGIN PAGE ****************************/

.page-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-login .logo {
    margin-bottom: 0
}

.login-header-c {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.page-login .logo > h1 {
    font-size: 48px;
    color: var(--ag-grey3);
    font-weight: normal;
    padding: 0;
}

.page-login .logo > h1 > span {
    display: inline-block
}

.page-login .logo > h1 > span:first-letter {
    font-weight: bold;
}

.page-login .separator {
    margin: 30px auto;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    position: relative;
    width: 100%
}

.page-login .separator::before {
    border-top: 1px solid #ddd;
    content: "";
    display: inline-block;
    height: 0;
    left: 10px;
    position: absolute;
    right: 10px;
    top: 9px;
    vertical-align: middle
}

.page-login .separator span {
    background-color: #ececec;
    display: inline-block;
    padding: 0 8px;
    position: relative;
    vertical-align: middle
}

@media screen and (min-width: 768px) {
    .page-login .separator {
        width: 50%
    }
}

@media screen and (min-width: 992px) {
    .page-login .separator {
        width: 31%
    }
}

.page-login .login-box {
    padding: 30px;
    text-align: left;
    width: 100%;

}

.page-login input[type="text"], .page-login input[type="password"],
.page-login input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px var(--ag-grey4) inset;
    color: #000;
}

.img-login {
    margin: 0 0 20px;
}

.forgotten-pwd {
    text-align: center;
    margin: 0px 0px 10px 0px;
}

.form-login-container {
    text-align: left;
    width: 100%;
    clear: both;
}

.page-login .login-box .title {
    font-size: 1.2em;
    line-height: 1;
    margin-bottom: 15px
}

.page-login .login-box .btn-login {
    margin-bottom: 30px;
    margin-top: 20px;
    position: relative;
    border-radius: 4px;
    color: white;
    background-color: var(--gd-primary);
}

.page-login .login-box .btn-login img {
    position: absolute;
    left: -7px;
    top: -5px;
}

@media screen and (min-width: 768px) {
    .page-login .login-box {
        width: 50%
    }
}

@media screen and (max-width: 768px) {
    .page-login .logo > h1 {
        font-size: 36px;
    }
}

@media screen and (min-width: 992px) {
    .page-login .login-box {
        width: 30%
    }
}

.page-login .links {
    text-align: center
}

.page-login .links p {
    font-size: .9em;
    margin-bottom: 0
}

.page-login .links p a:hover {
    text-decoration: none
}

/*************************************************************************/

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto
}

table {
    background-color: transparent
}

th {
    text-align: left
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th,
.table > thead > tr > td, .table > thead > tr > th {
    padding: 8px;
    line-height: 1.2;
    vertical-align: middle;
    border-top: 1px solid #ced4d8
}

.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ced4d8
}

.table > caption + thead > tr:first-child > td, .table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td, .table > thead:first-child > tr:first-child > th {
    border-top: 0
}

.table > tbody + tbody {
    border-top: 2px solid #ced4d8
}

.table .table {
    background-color: #fff
}

.table-condensed > tbody > tr > td, .table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td, .table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td, .table-condensed > thead > tr > th {
    padding: 5px
}

.table-bordered {
    border: 1px solid #ced4d8
}

.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
    border: 1px solid #ced4d8
}

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
    border-bottom-width: 2px
}

.table-striped > tbody > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #f4f6f7
}

.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
    background-color: #f2f3f4
}

table col[class*=col-] {
    position: static;
    float: none;
    display: table-column
}

table td[class*=col-], table th[class*=col-] {
    position: static;
    float: none;
    display: table-cell
}

.table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tbody > tr > td.active,
.table > tbody > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th,
.table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td,
.table > thead > tr.active > th, .table > thead > tr > td.active, .table > thead > tr > th.active {
    background-color: #f2f3f4
}

.table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr.active:hover > th,
.table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover {
    background-color: #e4e7e9
}

.table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tbody > tr > td.success,
.table > tbody > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th,
.table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td,
.table > thead > tr.success > th, .table > thead > tr > td.success, .table > thead > tr > th.success {
    background-color: #62ffa3
}

.table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr.success:hover > th,
.table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover {
    background-color: #48ff94
}

.table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tbody > tr > td.info,
.table > tbody > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th,
.table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td,
.table > thead > tr.info > th, .table > thead > tr > td.info, .table > thead > tr > th.info {
    background-color: #bce8fc
}

.table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr.info:hover > th,
.table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover {
    background-color: #a3e0fb
}

.table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tbody > tr > td.warning,
.table > tbody > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td,
.table > thead > tr.warning > th, .table > thead > tr > td.warning, .table > thead > tr > th.warning {
    background-color: #ffe799
}

.table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr.warning:hover > th,
.table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover {
    background-color: #ffe280
}

.table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tbody > tr > td.danger,
.table > tbody > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td,
.table > thead > tr.danger > th, .table > thead > tr > td.danger, .table > thead > tr > th.danger {
    background-color: #ffb0bf
}

.table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr.danger:hover > th,
.table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover {
    background-color: #ff96ab
}

@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 11.25px;
        overflow-y: hidden;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ced4d8;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive > .table {
        margin-bottom: 0
    }

    .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tfoot > tr > td, .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > thead > tr > td, .table-responsive > .table > thead > tr > th {
        white-space: nowrap
    }

    .table-responsive > .table-bordered {
        border: 0
    }

    .table-responsive > .table-bordered > tbody > tr > td:first-child,
    .table-responsive > .table-bordered > tbody > tr > th:first-child,
    .table-responsive > .table-bordered > tfoot > tr > td:first-child,
    .table-responsive > .table-bordered > tfoot > tr > th:first-child,
    .table-responsive > .table-bordered > thead > tr > td:first-child,
    .table-responsive > .table-bordered > thead > tr > th:first-child {
        border-left: 0
    }

    .table-responsive > .table-bordered > tbody > tr > td:last-child,
    .table-responsive > .table-bordered > tbody > tr > th:last-child,
    .table-responsive > .table-bordered > tfoot > tr > td:last-child,
    .table-responsive > .table-bordered > tfoot > tr > th:last-child,
    .table-responsive > .table-bordered > thead > tr > td:last-child,
    .table-responsive > .table-bordered > thead > tr > th:last-child {
        border-right: 0
    }

    .table-responsive > .table-bordered > tbody > tr:last-child > td,
    .table-responsive > .table-bordered > tbody > tr:last-child > th,
    .table-responsive > .table-bordered > tfoot > tr:last-child > td,
    .table-responsive > .table-bordered > tfoot > tr:last-child > th {
        border-bottom: 0
    }
}

.form-control {
    height: 30px;
    border-radius: 15px;
    display: block;
    width: 100%;
    padding: 2px 8px;
    font-size: 13px;
    line-height: 1.2;
    color: #212121;
    background-color: #fff;
    background-image: none;
    border: 1px solid #acb6bd;
    box-shadow: none
}

.form-control:focus {
    border-color: #60b4f6;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(96, 180, 246, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(96, 180, 246, .6)
}

.form-control::-moz-placeholder {
    color: #909da6;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #909da6
}

.form-control::-webkit-input-placeholder {
    color: #909da6
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #eceef0;
    opacity: 1
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 2px
}

.modal-header {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.2px;
    background-color: #f4f6f7;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px
}

.dropdown-menu > li {
    cursor: pointer;
    font-weight: 400;
    line-height: 2.2;
    color: #58656e;
}

.dropdown-menu > li > a {
    font-weight: 400;
    line-height: 2.2;
    color: #58656e;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: "Kodchasan", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.1;
    color: #363636
}

.h1 .small, .h1 small, .h2 .small, .h2 small, .h3 .small, .h3 small, .h4 .small,
.h4 small, .h5 .small, .h5 small, .h6 .small, .h6 small, h1 .small, h1 small,
h2 .small, h2 small, h3 .small, h3 small, h4 .small, h4 small, h5 .small,
h5 small, h6 .small, h6 small {
    font-weight: 400;
    line-height: 1;
    color: #ced4d8
}

.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 0
}

.h1 .small, .h1 small, .h2 .small, .h2 small, .h3 .small, .h3 small, h1 .small,
h1 small, h2 .small, h2 small, h3 .small, h3 small {
    font-size: 65%
}

.h4, .h5, .h6, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0
}

.h4 .small, .h4 small, .h5 .small, .h5 small, .h6 .small, .h6 small, h4 .small,
h4 small, h5 .small, h5 small, h6 .small, h6 small {
    font-size: 75%
}

.h1, h1 {
    font-size: 26px;
    padding: 0 0 0 0px;
    font-weight: 700;
}

.h2, h2 {
    font-size: 22px
}

.h3, h3 {
    font-size: 19px
}

.h4, h4 {
    font-size: 17px
}

.h5, h5 {
    font-size: 13px
}

.h6, h6 {
    font-size: 12px
}

p {
    margin: 0 0 7.5px
}

.lead {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4
}

@media ( min-width: 768px) {
    .lead {
        font-size: 19.5px
    }
}

.small, small {
    font-size: 92%
}

cite {
    font-style: normal
}

.mark, mark {
    background-color: #ffe799;
    padding: .2em
}

.bg-primary {
    color: #fff;
    background-color: #2196f3
}

a.bg-primary:hover {
    background-color: #0c7cd5
}

.bg-success {
    background-color: #62ffa3
}

a.bg-success:hover {
    background-color: #2fff85
}

.bg-info {
    background-color: #bce8fc
}

a.bg-info:hover {
    background-color: #8bd7fa
}

.bg-warning {
    background-color: #ffe799
}

a.bg-warning:hover {
    background-color: #ffdc66
}

.bg-danger {
    background-color: #ffb0bf
}

a.bg-danger:hover {
    background-color: #ff7d96
}

.page-header {
    padding-bottom: 6.5px;
    margin: 30px 0 15px;
    border-bottom: 1px solid #f4f6f7
}

ol, ul {
    margin-top: 0;
    margin-bottom: 7.5px
}

dl {
    margin-top: 0;
    margin-bottom: 15px
}

dd, dt {
    line-height: 1.2
}

dt {
    font-weight: 700
}

dd {
    margin-left: 0
}


blockquote {
    padding: 7.5px 15px;
    margin: 0 0 15px;
    font-size: 16.25px;
    border-left: 5px solid #f4f6f7
}


blockquote .small, blockquote footer, blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.2;
    color: #ced4d8
}

address {
    margin-bottom: 15px;
    font-style: normal;
    line-height: 1.2
}

.header-group {
    height: 45px;
}



.top-header .app-title {
    padding: 15px 0;
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    color: #5b5a5b;
}

.user-infos i {
    font-size: 40px;
    margin: 10px 10px 0 0;
    color: #0b16f9;
}

.user-infos-img {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    margin: 10px 0px 10px 10px;
}

.counter {
    position: relative;
    top: -15px;
    left: -10px;
    color: #4ee400;
}

.no-pointer-event {
    pointer-events: none;
}

.user-name {
    color: white;
    margin-bottom: 5px !important;
}

.user-infos span > span {
    color: white;
    display: block;
    margin: 10px 0 0;
    font-weight: bold;
}

.user-infos span.user-lang {
    color: white;
}

.top-header .dropdown-toggle {
    margin: 0 0 0 0;
}

.block-logo {
    padding-left: 0px;
}

.top-header .block-logo > img {
    height: 30px;
    margin-bottom: 12px;
    margin-left: -36px;
}

.form-search .form-control {
    margin: 10px 0 0;
    height: 30px;
    border-radius: 15px;
}

h1 .pictos-title {
    position: absolute;
    left: -41px;
    top: -51px;
    width: 84px;
    margin-top: 20px;
}

.title-span {
    display: block;
    color: white;
    width: 50px;
    height: 50px;
    margin: 32px 32px 5px 0;
    padding: 0 0;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    /*     chnaged style for dashboard */
    left: -30px;
    top: -38px;
}

.title-fonc {
    padding-top: 7px;
    padding-left: 15px !important;
}

span.picto-rub-1 {
    background: #6c6b6a;
}

h1 > span.picto-rub-2 {
    background: #435df7;
}

h1 > span.picto-rub-3 {
    background: #ff3cf3;
}

h1 > span.picto-rub-4 {
    background: #07ad07;
    color: #000;
}

h1 > span.picto-rub-5 {
    background: #f9032c;
    color: #fff;
}

h1 > span.picto-rub-6 {
    background: #06f728;
}

h1 > span.picto-rub-7 {
    background: #00cebc;
    color: #fff;
}

h1 > span.picto-rub-8 {
    background: #07ad07;
    color: #fff;
}

h1 > span.picto-rub-7.text {
    font-size: 16px;
}

h1 > span.picto-rub-13 {
    background: #6c6b6a;
}

/* h1 > span.picto-rub-13 { */
/*     background: #4a005b; */
/*     color: #ddd42c; */
/* } */
h1 > span.picto-rub-14 {
    background: #4a005b;
    color: #ddd42c;
}

h1 > span.picto-rub-15 {
    background: #ff3cf3;
    color: #000;
}

h1 > span.picto-rub-17 {
    color: #000;
}

h1 > span.picto-rub-10 {
    background: #f3f906;
    color: #000000;
}

h1 > span.picto-rub-11 {
    background: #068cb5;
}

h1 > span.picto-rub-20 {
    background: #16ecf7;
    color: #000000;
}

h1 > span.picto-rub-21 {
    background: #d9dadd;
    color: #000000;
}

h1 > span.picto-rub-16 {
    background: #f9d608;
    color: #000000;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
    background: #6c6b6a;
    color: #fff;
}

.nav > li > a:focus, .nav > li > a:hover {
    text-decoration: none;
    background-color: #f4f6f7
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
    font-size: 14px;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
    text-decoration: underline;
}

.main-nav {
    margin-top: 5px;
}

.block-action-list {
    margin: 20px 0;
}

.page-title .block-action-list {
    margin: 10px 0;
}

.block-action-list > button {
    border: 1px solid #0000ff;
    font-size: 16px;
}

ol {
    border: 1px solid #DDD;
    border-bottom: none;
}

ol .row > div:last-child {
    border-left: 1px solid #DDD;
    padding: 20px 10px;
}

ol .row > div:first-child {
    padding: 10px 25px;
}

ol li:nth-child(odd) {
    background-color: #f4f6f7;
}

ol .tree-node {
    padding: 0;
}

.search-list {
    margin: 20px 0 0;
}

.table-bordered {
    border: 1px solid #000;
}

.table-bordered {
    border: none;
}

.table-bordered th {
    background: #333;
    color: #fff;
    /*border-radius: 10px 10px 0 0;*/
    border: none !important;
    text-align: center;
}

.table-bordered th > a {
    color: #fff;
    font-size: 12px;
}

.search-list input.form-control {
    border: 1px solid #000;
}

.main-content {
    position: relative;
}

.collapse-menu {
    position: absolute;
    right: 0%;
    border-radius: 0;
    bottom: -33px;
    background: #000;
    color: #fff;
}

.collapse-menu-filtre {
    position: absolute;
    right: 0%;
    border-radius: 0;
    bottom: -33px;
    background: #333;
    color: #fff;
}

h1 {
    position: relative;
    z-index: 0;
}

#map {
    width: 100%;
    height: 430px;
}

.ui-grid-icon-menu {
    color: #FFF;
}

.ui-grid-menu-button {
    background: #333;
    border: none;
}

.ui-grid-list-action {
    position: absolute;
    margin: 10px;
}

.ui-grid-cell {
    height: auto !important;
    /*     overflow : visible; */
}

.ui-grid-cell-custom {
    height: auto !important;
    overflow: visible;
}

.actions {
    overflow: visible !important;
}

.actions i {
    margin: 2px 5px;
    color: var(--ag-grey1);
}

.actions i:hover {
    color: var(--dc-primary);
}

.actions.gd span {
    margin: 0px 0px;
    color: var(--ag-grey1);
    width: unset;
    vertical-align: top;
    height: unset;
    line-height :unset;
}

.actions.gd span i:hover {
    color: var(--hd-accent);
}

.actions.gd i:hover {
    color: var(--gd-accent);
}

.actions.hd i:hover {
    color: var(--hd-accent);
}

.logo-img {
    display: inline-block;
    height: 40px;
}

.custom-header {
    background: #000;
    color: #fff;
    border-radius: 10px 10px 0 0;
    border: none !important;
    text-align: center;
    white-space: nowrap;
    word-break: inherit;
}

.gridStyle {
    border: none;
    text-align: center;
}

.grid-action-cell {
    text-align: left;
}

[ui-grid-row] {
    display: table-row;
}

.ui-grid-row, .ui-grid-cell {
    /*  height: auto!important;*/
    vertical-align: middle;
}

.ui-grid-cell {
    /*    border-left: 1px solid #000 !important;
    border-bottom: 1px solid #000;*/

}

.ui-grid-cell {
    float: none;
    display: table-cell;
}

.custom-colonne {
    border-left: 1px solid #D4D4D4;
}

.cheminScroll .scrollArea {
    height: 280px
}

/*.ui-grid-header-cell, .ui-grid-cell-contents {
    white-space: normal;
    padding: 10px 0;
    word-break: break-word;
    text-align: center;
}*/
.ui-grid-header-cell {
    text-align: center;
}

.f-icons-group {
    margin-top: 10px;
    margin-right: 15px;
}

.f-textarea-c {
    margin: 5px 15px;
}

.f-descr {
    width: 200px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

#loading {
    opacity: 0.6;
    visibility: hidden;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 99999;
    background-color: rgb(0, 0, 0)
}

#custom-loading {
    opacity: 0.6;
    visibility: hidden;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 99999;
    background-color: rgb(0, 0, 0)
}

.btn-new-action {
    margin: 0 0 20px;
}


.form-control {
    border-radius: 2px;
    background: #f9f9f9;
}

/*----------------------------------------------------------------------------------- */
/*  Table version mobile */
/*----------------------------------------------------------------------------------- */
@media only screen and (max-width: 800px) {
    .table td[data-title="Actions"] {
        text-align: left !important
    }

    /*.collapse-menu {*/
    /*display: none;*/
    /*}*/
    .table td {
        position: relative;
        padding: 15px 0 15px 135px !important;
    }

    .table table, .table thead, .table tbody, .table th, .table td, .table tr {
        display: inline-block;
        width: 100%
    }

    .table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .table tr {
        border: 1px solid #ccc;
    }

    .table .odd td, .table .even td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50% !important;
        white-space: normal;
        text-align: left;
    }

    .table td:before {
        position: absolute;
        top: 15px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }

    .table td:before {
        content: attr(data-title);
    }

    ol .row > div:last-child {
        border-left: none;
        padding: 20px 30px;
    }
}

@media ( max-width: 768px) {
    /* .user-block {
         display: none !important;
         visibility: hidden !important;
     }
 */
    .top-header {
        height: 110px;
    }

    .mobile-user-block {
        display: block !important;
        visibility: visible !important;
        margin-bottom: 20px;
    }

    .btn-switch-menu {
        position: relative !important;
        right: 0;
    }

    .nav.main-nav {
        margin: 20px 0 0;
    }

    .nav.main-nav > li {
        float: left;
    }

    h1 .pictos-title {
        left: 0px;
    }

    h1 > span {
        left: 11px;
    }

    h1 {
        padding: 0 0 0 84px;
    }
}

/* Dashboard style */
.dashboard {
    margin-top: 30px;
}

.controls {
    margin-bottom: 20px;
}

.page-header {
    margin-top: 20px;
}

ul {
    list-style: none;
}

.box {
    height: 100%;
    background-color: #fff;
}

.border-box {
    border: 1px solid #ccc;
}

.box-header {
    background-color: #eee;
    padding: 5px 30px 7px 10px;
    border-bottom: 1px solid #ccc;
    position: relative;
}

.box-header h3 {
    font-size: 14px;
    margin-top: 10px;
    display: inline-block;
}

.box-content {
    padding: 10px;
    height: 90%;
}

.dx-chart {
    height: 95%;
}

form {
    margin-bottom: 0;
}

.gridster {
    border: 1px solid #ccc;
}

.block-home-graphe img {
    width: 400px;
    height: 400px;
}

.block-content {
    min-height: 500px;
}


/*Drag and drop*/

/**
 * For the correct positioning of the placeholder element, the dnd-list and
 * it's children must have position: relative
 */
.dragDrop .dnd-list, .dragDrop .dnd-list > li {
    position: relative;
}

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
.dragDrop .dnd-list {
    min-height: 42px;
    padding-left: 0px;
}

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
.dragDrop .dnd-list .dndDraggingSource {
    display: none;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
.dragDrop .dnd-list .dndPlaceholder {
    display: none;
    background-color: #ffffff;
    padding: 0px 0px;
    height: 0px;
    /* border: 0px !important;*/
}

/**
 * The dnd-lists's child elements currently MUST have
 * position: relative. Otherwise we can not determine
 * whether the mouse pointer is in the upper or lower
 * half of the element we are dragging over. In other
 * browsers we can use event.offsetY for this.
 */
.dragDrop .dnd-list li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: block;
    margin-bottom: -1px;
    padding: 10px 15px;
    /* Disable text selection if item is not draggable */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/**
 * Handle positioning
 */
.dragDrop .handle {
    cursor: move;
    margin: 0px 10px;
}

.dragDrop .name {
    margin-left: 20px;
}

/*End drag and drop*/


.col-md-1-5 {
    width: 13.333333% !important;
}

.col-md-0-5 {
    width: 5.333333% !important;
}

.carac_right_border {
    border-right: 1px solid #DDD;
    padding: 20px 10px;
}

.carac_left_border {
    border-right: 1px solid #DDD;
    padding: 20px 10px !important;
    padding-top: 5% !important;
}

h1.organisations > span > i:first-child {
    left: 5px;
    top: -5px;
}

h1.organisations > span > i {
    font-size: 19px;
    position: relative;
    left: -2px;
}

h1.organisations > span > i.last-icone-home {
    left: -4px;
    top: -20px;
    font-size: 12px;
    font-weight: normal;
}

.div-middle {
    display: inline-block;
    vertical-align: middle
}

.btn-change-input-type {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.2;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.btn-change-input-type.active:focus, .btn-change-input-type:active:focus,
.btn-change-input-type:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.btn-change-input-type:focus, .btn-change-input-type:hover {
    color: #2196f3;
    text-decoration: none
}

.btn-change-input-type.active, .btn-change-input-type:active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.my-hdh-menu {
    background: #6c6b6a;
    width: 30px;
    height: 30px;
    margin: 5px 0 5px 0;
    padding: 8px 0;
    text-align: center;
    border-radius: 50%;
    border: 3px solid #333;
    display: inline-block
}

span.btn-nav-6, span.btn-nav-6:hover, span.btn-nav-6:focus {
    background: #06f728;
    color: #000;
}

span.btn-nav-11, span.btn-nav-11:hover, span.btn-nav-11:focus {
    background: #f3f906;
    color: #000;
}

span.btn-nav-7, span.btn-nav-7:hover, span.btn-nav-7:focus {
    background: #00cebc;
    color: #000;
}

.btn-cancel {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.btn-save {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.background-row {
    background-color: #A5AdB5 !important;
}

.background-row-highlighted {
    background-color: #f4f5d3 !important;
}

.btn-filter-off {
    width: 18px;
    height: 19px;
    cursor: pointer;
}

.btn-invisible {
    background: transparent;
    border: 0;
    cursor: default;
}

.btn-invisible:disabled {
    background: transparent;
    border: 0;
    cursor: default;
}

.btn-invisible:disabled:hover {
    background: transparent;
    border: 0;
    cursor: default;
}

.btn-invisible:hover {
    background: transparent;
    border: 0;
    cursor: default;
}

.a-btn-active {
    cursor: pointer
}

.a-btn-inactive {
    cursor: default;
    color: gray !important;
}

.cell-custom-height {
    height: initial !important;
}

.ui-grid-cell-map {
    height: 30px !important;
    /*     overflow : visible; */
}

.ui-grid-cell-30 {
    height: 30px !important;
}

#uploadRemainigTime {
    color: black;
}

.dashed-area {
    padding: 15px !important;
    border: 2px #ACB6BD dashed !important;
    border-radius: 5px !important;
    line-height: 34px !important;
    margin: 0 auto !important;
}

.flow-parent {
    text-align: center;
}

.grid-align {
    text-align: left;
    display: table-cell;
    vertical-align: middle;
}

.toggle-in-grid {
    margin: 3px auto;
    margin-left: 7px;
}

.toggle.ios, .toggle-on.ios, .toggle-off.ios {
    border-radius: 20px;
}

.toggle.ios .toggle-handle {
    border-radius: 20px;
}

.toggle.btn {
    line-height: 2;
}

.toggle-handle {
    border-radius: 20px;
}

.toggle.btn-sm {
    width: 50px !important;
    height: 30px !important;
}

.toggle.btn-sm-75 {
    width: 75px !important;
    height: 30px !important;
}

.link-disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: grey;
}

.btn-add-carac {
    margin-top: -3px !important;
    margin-right: 17px !important
}

.nav-tabs > li.active > a > span.badge {
    background-color: #ced4d8;
}

.nav-tabs > li > a > span.badge {
    background-color: #000;
}

.file-list-element {
    padding: 10px 0 10px 5px;
    border: 1px solid #ced4d8;
    border-bottom: none;
    background-color: #F2F3F4;
    height: 40px;
}

.file-list-element input {
    height: 90%;
}

.file-list-element:hover {
    background-color: #FFF;
}

.file-list-element-edit {
    border-bottom: 1px solid #ced4d8 !important;
}

.mapping-cara-files-modal-dialog {
    overflow-y: initial !important;
}

.mapping-cara-files-modal-body {
    height: 400px;
    overflow-y: auto;
}

.file-name-carac-mapping {
    cursor: pointer;
}

.read-only .tag-item {
    background: linear-gradient(to bottom, rgba(240, 249, 255, 1) 0%,
    rgba(203, 235, 255, 1) 47%, rgba(161, 219, 255, 1) 100%) !important;
}

.file-actif {
    font-weight: bold;
    color: #0C7CD5;
}

.file-non-actif {
    font-weight: bold;
    color: #E3002C;
}

.width-margin-select-mapping {
    width: 100%;
    height: 28px;
}

.width-margin-input-mapping {
    width: 100% !important;
    height: 25px !important;
    color: #02a2f9 !important;
}

.margin-btn-del-mapping {
    margin-left: 10px !important;
}

.remove-link-button {
    margin-left: 0px !important;
}

.mapping-error-message {
    width: 100%;
}

.mapping-cara-modal-body {
    min-height: 250px;
    max-height: 500px;
    margin-bottom: 30px;
    overflow-y: auto;
}

.mapping-cara-modal-footer {
    margin-top: 30px;
}

.clickable-link {
    color: #0288d1;
    cursor: pointer;
}

.add-new-mapping {
    padding-left: 0px;
    margin-left: 0px;
}

.file-header-data-grid .ui-grid-menu-mid {
    height: 260px;
}

.file-fieldset {
    border: 1px solid #c9cfd3 !important;
    border-radius: 3px;
    padding: 0 1.3em .5em 1.3em !important;
    -webkit-box-shadow: 0 0 0 0 #000;
    box-shadow: 0 0 0 0 #000;
    width: 100% !important;
}

.file-fieldset-parent {
    margin-top: -10px !important;
}

.mapped-count {
    color: #0288d1;
    font-weight: 500;
}

#delFormulaModal {
    z-index: 1500;
    margin-top: 10%;
}

.analyse-mode-btn-active {
    color: #fff;
    background-color: #00c853;
    border-color: #00c853;
}

.file-analyser-mode button {
    width: 137px;
    margin-right: 3%;
}

.file-analyser-mode {
    text-align: center;
}

.inline {
    float: left;
    padding-left: 0px;
}

.nav-button-files {
    width: 5px;
    cursor: pointer;
}

.center-vertically {
    margin-top: 33vh;
    transform: translateY(-50%);
}

.cron-files {
    margin-left: 0;
}

.file-analyzer-fieldset {
    border: 1px solid #c9cfd3 !important;
    border-radius: 8px;
    padding: 0 1.3em .5em 1.3em !important;
    -webkit-box-shadow: 0 0 0 0 #000;
    box-shadow: 0 0 0 0 #000;
    width: 100% !important;
}

.file-analyzer-fieldset-iframe {
    padding: 0 0 .5em 0 !important;
}

.left {
    margin-left: -18px;
}

.entite-type-select {
    width: 260px;
    margin: 0px 5px;
}

.flex-row {
    display: flex;
    align-items: center;
}

.file-depot-radio {
    margin-bottom: 20px !important;
    padding-left: 0px;
}

.file-depot-radio input[type=radio] {
    width: 18px;
    height: 18px;
    margin-top: -1px;
    vertical-align: middle;
}

/*
.file-depot-radio input[type=checkbox] {
    vertical-align: middle;
}
*/

.job-details {
    font-weight: 600;
    color: #0C7CD5;
    text-align: center;
}

#cronButton {
    height: 25px !important;
}

.version-info {
    margin: 12px 0 0 auto;
}

.parent-div {
    width: 100%;
    text-align: center;
}

.child-div {
    display: inline-block;
}

.valid-password {
    color: green;
    padding-left: 0px;
}

.hr {
    border-bottom: 1px solid #ddd;
}

.bottom-space {
    margin-bottom: 20px !important;
}

.full-width {
    width: 100%
}

.height-30 {
    height: 30px;
}

.height-25 {
    height: 25px !important;
}

#mapping, .mapper {
    text-align: center;
    vertical-align: middle;
}

.mapper {
    border-right: 1px solid !important;
    border-left: 1px solid !important;
    border-bottom: 0px !important;
    border-color: #d4d4d4 !important;
    width: 100%;
}

.mapper-z-index {
    z-index: 999999999;
}

#entiteTypeMapperUiSelect span {
    width: 100% !important;
}

#dataSourceMapperUiSelect span {
    width: 100% !important;
}

.margin-0  {
    margin: 0px !important;
}

.padding-0 {
    padding: 0px !important;
}

.no-border {
    border: 0px !important;
}

.pwd-message {
    margin-top: 8px !important;
    margin-bottom: 5px !important;
    color: #888a85;
}

.gridster-item {
    z-index: auto;
}

/* The spinner */
@keyframes spinner {

    to {
        transform: rotate(360deg);
    }

}

.spinner-wid, .spinner-wid:before {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
}

.spinner-wid:before {
    content: '';
    display: block;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner .6s linear infinite;
}

.spinner-wid-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
}

/* Animations */
.spinner-wid-add, .spinner-wid-remove {
    animation-fill-mode: both;
    animation-duration: .4s;
}

.spinner-wid-add {
    animation-name: spinner-add;
}

@keyframes spinner-add {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }

}

.spinner-wid-remove {
    animation-name: spinner-remove;
}

@keyframes spinner-remove {
    to {
        transform: scale(0);
    }

}

.disabledDiv {
    pointer-events: none;
    /* for "disabled" effect */
    opacity: 0.5;
    background: #CCC;
}

.enabledDiv {
    pointer-events: auto !important;
    /* for "disabled" effect */
    opacity: 1 !important;
    background: none !important;
}

.watermark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    font-size: 3em;
    width: 100%;
    text-align: center;
    z-index: 1000;
}

.hieght-100 {
    height: 100% !important;
}

.link-creator-panel {
    margin-left: 30px;
    margin-right: 30px;
}

.link-creator-panel-body {
    padding-left: 3%;
    padding-right: 2%;
}

.sub-menu-nav-bar {
    top: 0;
    left: 100%;
    margin-top: -1px;
    float: right;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu:hover > ul.dropdown-menu.sub-menu-nav-bar {
    display: block;
}

.nav-menu-background {
    background: #F2F3F4 !important;
}

.nav-icon-menu {
    margin-top: 10px;
    color: black;
}

.ui-select-bootstrap .ui-select-choices-row.active > a {
    color: #000000;
}

.ui-select-bootstrap .ui-select-choices-row.active.disabled > a {
    color: #000000;
}

.ui-select-bootstrap .ui-select-choices-row > a {
    color: #428bca;
}

.form-horizontal .control-label {
    text-align: left;
}

.db-new-btn {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
}

.db-connexion {
    margin-bottom: 10px;
    margin-right: 4px;
}

.margin-top-10 {
    margin-top: 10px;
}

.filename {
    font-weight: 400;
    margin-left: 5px;
    margin-top: 10px;
}

.header-uploader-btn {
    border-radius: 50px;
    background-color: black;
}

.db-header-uploader-btn {
    border-radius: 10px;
}

.circle-btn {
    line-height: 1.33;
    border-radius: 25px;
}

.headers-table {
    max-height: 20%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.tab-borders {
    border: 1px solid #ddd;
}

.side-bar-source {
    border-left: 15px solid #4d9900;
}

.side-bar-entite {
    border-left: 15px solid #3385ff;
    margin-top: 15px !important;
}

.full-height {
    height: 100% !important;
}

.grey-message {
    color: #7c7b7c;
    font-size: 11px;
}

.scrollable-dropdown-menu .dropdown-menu {
    max-height: 220px;
    overflow-y: auto;
}

.no-left-right-border {
    border-top: 1px solid #ced4d8;
    border-bottom: 1px solid #ced4d8;
}

.border-left-td {
    border-left: 1px solid #ced4d8;
}

.border-right-td {
    border-right: 1px solid #ced4d8;
}

.icon {
    width: 64px;
    height: 64px;
}

.icon-grid {
    width: 32px;
    height: 32px;
}

.icon-16 {
    width: 16px;
    height: 16px;
}

.icon-23 {
    width: 23px;
    height: 23px;
}

.icon-20 {
    width: 20px;
    height: 20px;
}

.icon-24 {
    width: 24px;
    height: 24px;
}

.icon-30 {
    width: 30px;
    height: 30px;
    color: black;
}

.icon-width-15 {
    width: 15px;
    height: 30px;
    color: black;
}

.icon-48 {
    width: 48px;
    height: 48px;
}

.icon-36 {
    width: 36px;
    height: 36px;
}

.icon-45 {
    width: 45px;
    height: 45px;
}

.icon-100 {
    width: 100px;
    height: 100px;
}

.icon-70 {
    width: 70px;
    height: 70px;
}


.black-link {
    cursor: pointer;
    color: #252525;
}

.black-link:hover, .black-link:active, .black-link:visited {
    cursor: pointer;
    color: #252525;
    text-decoration: none;
}

.strong {
    font-weight: bold;
}

.selected-widget {
    background-color: #ebf0fa;
}

.width-83 {
    width: 83%;
}

.width-84 {
    width: 84%;
}

.toast {
    z-index: 1005;
}

.top-space-65 {
    margin-top: 65px;
}

.top-space-70 {
    margin-top: 70px;
}

.top-space-80 {
    margin-top: 80px;
}


.icon-test {
    width: 80px;
    height: 70px;
}

.icon-test-req {
    width: 67px;
    height: 30px;
}

.icon-18 {
    width: 18px;
    height: 18px;
}

.dropHere {
    display: block;
    background-color: #ddd;
    padding: 10px 15px;
    min-height: 42px;
}

.bottom-space-20 {
    margin-bottom: 20px;
}

.font-size-18 {
    font-size: 18px;
}

.disabled-li {
    pointer-events: none;
    opacity: 0.7;
}

.formula-group-icon {
    font-family: 'Pacifico', cursive;
    margin-right: 5px;
    font-size: 14px;
}

.color-red {
    color: red;
}

.color-green {
    color: green;
}

.color-blue {
    color: #00136f;
}

.color-yellow {
    color: #ff5808;
}

.color-black {
    color: black !important;
}

.color-white {
    color: white !important;
}

.color-wheat {
    color: wheat !important;
}

.color-wheat {
    color: wheat !important;
}

.color-gray {
    color: #ddd !important;
}

.color-darkgreen {
    color: darkgreen !important;
}

.color-search-value {
    color: #078307;
}

.color-orange-menu {
    color: #E9850E;
}

.arg-list-last-element {
    border-bottom: 1px solid #ddd !important
}

.formula-suggestions-max-height {
    height: 330px;
    overflow: auto;
    overflow-x: hidden;
}

.formula-suggestions-args-max-height {
    max-height: 330px;
    overflow: scroll;
    overflow-x: hidden;
}

.formula-type-color {
    color: #585a5a;
}

.popover {
    left: 550px;
    top: 450px;
}

.function-info-div {
    width: 450px;
    z-index: 100000;
    position: absolute;
    background: #ffffff;
    border: solid 1px rgba(133, 140, 140, 0.94);
    box-shadow: 3px 3px 2px rgba(136, 136, 136, 0.46);
    padding: 10px 10px 10px 10px;
}

.selected-param {
    background: #e0eaf9;
}

.link-ui-select-choices {
    position: fixed;
    top: auto;
    left: auto;
    width: 300px !important;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.circled-icon {
    display: inline-block;
    border-radius: 60px;
    border-color: #000000
}

.toggle :disabled {
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;
    opacity: 0.65;
}

.dropdown-submenu:hover > ul.dropdown-menu.hover-dispalyed-menu {
    display: block;
}



#datablock_form {
    margin-top: 60px;
}



.font-icon-parent {
    width: 56px;
}

.fix-rotation {
    margin-left: 5px;
    margin-bottom: 2px;
}




.collaps-arrow {
    margin-left: auto;
    margin-right: 5px;
}

.let-dorpdown-menu {
    left: -199px !important;
    width: 200px !important;
}

#qualityToggle .toggle {
    width: 40px !important;
    height: 20px !important;
}

.widget-type-icon {
    font-family: 'Pacifico', cursive;
    margin-right: 5px;
    font-size: 12px;
}

.cursor-default {
    cursor: default;
}

.quality-tooltip {
    position: fixed;
    background: #fff;
    border: 1px solid #888888;
    box-shadow: 1px 1px 1px #888888;
    border-radius: 0.25em;
    z-index: 999999999;
    overflow-y: auto;
}

.widget-row-tooltip {
    background: #f7f7f7;
    border-color: #9c9c9c;
}

.widget-directives-header {
/*    background: var(--ag-grey4);*/
    border: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.widget-directives-header h2, .widget-directives-header-close {
    color: var(--text-color);
    opacity: 1 !important;
    margin-top: 0px !important;
}

.widget-directives-header-close :hover {
    color: #ffdd47;
}

.widget-dir-save-icon {
    margin-top: -15px;
    margin-right: 10px;
}


.datablock-menu-popup-height {
    height: 740px;
}

.height-260 {
    height: 260px;
}

.height-252 {
    height: 252px;
}

.height-238 {
    height: 238px;
}

.controls {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid transparent;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 300;
    height: 29px;
    margin-left: 17px;
    margin-top: 10px;
    outline: none;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

.controls:focus {
    border-color: #4d90fe;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}


.depot-zone {
    background: #fff;
    border-top: none;
}

.depot-reading-zone {
    background: #fff;
}

.depot-data-zone {
    background: var(-ag-grey4);
}

.depot-cron-zone {
    background: #ececec;
}

.bottomlpadding-10 tr td {
    padding-bottom: 10px;
}

select.ui-grid-filter-select {
    color: black;
    background: #EEEEDD;
}

.highlight {
    background: #b3d4fc;
}

#filterDepotSelect .ui-select-toggle {
    height: 19px !important;
}

.ui-select-choices-row-inner {
    min-height: 19px;
}

.kind-of-pink {
    background: #ffe9ee;
}

.kind-of-green {
    background: #ebfff3;
}

.no-right-padding {
    padding-right: 0px !important;
}

.no-left-padding {
    padding-left: 0px !important;
}

.no-top-padding {
    padding-top: 0px !important;
}

.strong {
    font-weight: 700;
}

.auto-overflow {
    overflow: auto !important;
}

.import-fieldset {
/*    border: 1px solid #c9cfd3 !important;
    border-radius: 8px;*/
    padding: 0 1.3em .5em 1.3em !important;
    -webkit-box-shadow: 0 0 0 0 #000;
    box-shadow: 0 0 0 0 #000;
/*    overflow-x: hidden;
    overflow-y: scroll;*/
}

.grey {
    color: grey !important;
}

.tag-filter-btn {
    width: 100%;
    height: 18px;
    padding: 0px;
    background: #EEEEDD;
    color: black;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.tag-filter-btn:hover, .tag-filter-btn:focus, .tag-filter-btn:active {
    background: #EEEEDD;
    color: black;
}

.tag-filter-btn span {
    float: right;
    margin-top: 5px;
    margin-right: 5px;
}

.tag-filter-btn-graph {
    height: 30px !important;
    width: 70px !important;
    background-color: #F2F3F4;
}

#tagFilterEl .multiselect-parent, #tagFilterElMap .multiselect-parent, #tagFilterElDashboard .multiselect-parent, #tagFilterElTimeLine .multiselect-parent, #tagFilterElComparator .multiselect-parent {
    width: inherit;
    padding-right: 12px;
}

.ngCellText {
    vertical-align: middle;
}

.flex-parent {
    display: flex;
    justify-content: inherit;
}

.flex-children {
    align-self: center;
}

.flex-center {
    justify-content: center;
}

.flex-left {
    justify-content: flex-start;
}

.flex-right {
    justify-content: flex-end;
}

.loading-data-icons {
    border-left: solid 1px #6F6F6F;
    border-right: solid 1px #6F6F6F;
    padding-left: 3px;
    padding-right: 3px;
}

#structuredListGrid .ui-grid-row .ui-grid-cell.ui-grid-row-header-cell,
#unstructuredListGrid .ui-grid-row .ui-grid-cell.ui-grid-row-header-cell,
#depotsGridList .ui-grid-row .ui-grid-cell.ui-grid-row-header-cell {
    height: 40px !important;
}

#graphTagsFilter .option {
    padding: 1px 3px 1px 3px;
}

.width-90-per {
    width: 90% !important;
}

.tag-graph-elm {
    padding-left: 5px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .15);
}

.tag-list-item {
    width: 90% !important;
}

.btn-dark {
    color: #343a40;
    background-color: #fff;
    border-color: #343a40;
}

.btn-dark:hover {
    color: #fff !important;
    background-color: #343a40 !important;
}

.width-button-repersist {
    width: 35%;
}

.hover_icon_green:hover {
    color: green;
}

.dont-break-out {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-select-match-text {
    width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 4px;
}



.normal-font-weight {
    font-weight: normal !important;
}

.form-group.required .control-label:after {
    content: "*";
    color: red;
    margin-left: 5px;
}

.justify-depot-form {
  display:flex;
  justify-content: space-between;
  width: 70%
}

.remove-required:after {
    content: "" !important;
    margin-left: 5px;
}

#identitiesGrid.grid .ui-grid-header-cell . {
    height: 40px !important;
}

.bordered-div {
    margin: 10px 10px 10px 10px;
    padding: 10px 10px 10px 10px;
    border: 1.5px groove #000000;
    border-radius: 10px;
}

.bordered-div-active {
    border: 1.5px groove red !important;
}

.bordered-div-active:hover {
    background-color: lightslategray;
}

#toast-container {
    z-index: 19999999997 !important;
}

.grey-null {
    color: #c7c7c7 !important;
}

.empty-grid-text {
    position: relative;
    top: -200px;
    font-size: 22px;
    color: #c7c7c7 !important;
}

.centered-div {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}

.left-centered-div {
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.no-resize {
    resize: none;
}

.input-group-addon-edited {
    padding: 0 6px;
    color: inherit;
    background-color: transparent;
    border: none;
}

.highlighted {
    background-color: #b1c3ff !important;
}

.highlighted-input {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1) !important;
    border: 2px solid #b1c3ff !important;
}

.operator-semantic {
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #0a0a0a;
    font-weight: 900;
    padding: 2px;
}

.input-has-error {
    border-color: #b00022 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
}

.input-has-valid {
    border-color: green !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
}

.input-has-error:focus {
    border-color: #7d0018 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ff1744 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ff1744 !important;
}

.input-group-reset-padding {
    padding-left: 15px !important;
    padding-right: 15px !important;
    float: left !important;
}

.function_unauthorised {
    color: gray !important;;
    font-size: 30px !important;;
    text-align: center !important;;
}

.avatar-white {
    background: white;
}

.avatar {
    border-radius: 50%;
    width: 92px;
    height: 95px;
    border: 2px #afafaf solid;
}

.header-divider {
    border-right: solid 2px white !important;
}

.inherit-height {
    height: inherit;
}

.inherit-width {
    width: inherit !important;
}

.handledata-logo {
    width: 100%;
    height: 300px;
    border: 1px black solid;
    border: 0px;
}

.logoDivider {
    border-left: 6px solid green;
    height: 72px;
}

.btn-green {
    background-color: #359b54;
    color: #ffffff;
    font-weight: bolder;
    border-radius: 6px;
    height: 30px;
    border: none !important;
}

.btn-nobackground {
    background-color: #fff;
    color: #168f48;
    font-weight: bolder;
    border-radius: 6px;
    height: 30px;
    border: 1px #1aaa55 solid;
}

.btn-nobackground:hover {
    background-color: #b2e7c8
}

.btn-nobackground-red {
    background-color: #fff;
    color: red;
    font-weight: bolder;
    border-radius: 6px;
    height: 30px;
    border: 1px red solid;
}


.btn-nobackground-green {
    background-color: #fff;
    color: #359b54;
    font-weight: bolder;
    border-radius: 6px;
    height: 30px;
    border: 1px #359b54 solid;
}

.btn-nobackground-red:hover {
    background-color: #FCEDEE
}


.test:hover {
    background-color: red !important;
}

.btn-green {
    background-color: #359b54;
    color: #ffffff;
    font-weight: bolder;
    border-radius: 6px;
    height: 30px;
    border: none !important;
}

.btn-nobackground {
    background-color: #fff;
    color: #168f48;
    font-weight: bolder;
    border-radius: 6px;
    height: 30px;
    border: 1px #1aaa55 solid;
}

.btn-nobackground:hover {
    background-color: #b2e7c8
}

.btn-nobackground-red {
    background-color: #fff;
    color: red;
    font-weight: bolder;
    border-radius: 6px;
    height: 30px;
    border: 1px red solid;
}

.btn-nobackground-red:hover {
    background-color: #FCEDEE
}

.padding-left-right-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.padding-left-0 {
    padding-left: 0px;
}

.padding-right-0 {
    padding-right: 0px;
}

.padding-bottom-0 {
    padding-bottom: 0px;
}

.padding-left-5 {
    padding-left: 5px;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-left-20 {
    padding-left: 20px;
}

.padding-left-30 {
    padding-left: 30px;
}

.padding-left-50 {
    padding-left: 50px;
}

.padding-right-5 {
    padding-right: 5px;
}

.padding-right-20 {
    padding-right: 20px;
}

.padding-top-0 {
    padding-top: 0px;
}

.padding-top-5 {
    padding-top: 5px;
}

.padding-right-5 {
    padding-right: 5px;
}


.padding-left-0 {
    padding-left: 0px;
}

.padding-10 {
    padding: 10px;
}

.time-line-tab {
    overflow: auto;
    margin-bottom: 30px;
}

/*Give ui select dropdown a bigger z-index*/
.ui-select-bootstrap.open {
    z-index: 10000 !important;
}

.mapping-integration-express {
    padding-right: 0px;
    padding-left: 0px;
}

.modal-element-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.express-integration-header-close {
    color: white;
    opacity: 1 !important;
    margin-top: 5px !important;
}

.ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.green-message {
    color: #2d7d24;
}

.delete-report-consumers {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}

.help-icon {
    width: 24px;
    color: black;
}

.web-services-frame {
    width: 100%;
    height: 76vh;
}

/*Used to replace <center>*/
.center-element {
    text-align: center;
}
.center-element > div , .center-element > a, .center-element > img{
    margin-left: auto;
    margin-right: auto;
}

.column-stat-element {
    padding: 10px;
    height: 210px;
}

#mongodbCollection > [uib-typeahead-popup].dropdown-menu {
    max-height: 190px;
    overflow-y: scroll;
}

.font-weight-normal {
     font-weight: normal;
}

.selected-formula-element {
    background: #d6d6d6;
}

.suggestions-list-elm {
    height: 27px;
}

.not-allowed {
    cursor: not-allowed;
}

.formula-label {
     overflow: hidden;
     white-space:nowrap;
     text-overflow:ellipsis;
     width:300px;
     display:inline-block;
 }

 #pivotColumnsList {
    height: 250px;
    padding-bottom: 8px;
 }

 .padding-top-2 {
    padding-top: 2px
 }


.highlight-summary-col:hover{
    background-color: #ddd;
}

#dbFilterConditionPanel {
    width: 100%;
    height: 760px;
    padding-bottom: 5px;
}

#dbFilterConditionPanelBody {
    max-height: 640px;
    overflow-y: scroll;
}
#dbFilterColumnsPanelBody {
    height: 695px;
    overflow: auto;
}

#dbFilterConditionTextPanel {
    max-height: 65px;
    overflow-y: auto;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.map-conf-element {
    background: #ddd;
    padding-top: 10px;
}

.ui-select-toggle .glyphicon-remove {
    color: black;
    margin-top: 5px;
}

.add-color {
    color: #578445;
}

.a-link-no-decoration:hover {
     text-decoration: none;
}

.tags-input-centering .tags {
       overflow-y: auto;
       max-height: 45px;
}

.pub-date-picker .dropdown-menu {
  position: absolute !important;
}

.centring_element_buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 1.2em;
}

.pub-date-picker .datetimepicker {
  width: 270px !important;
}

.rules-group-container {
    overflow: hidden;
}

.md-switch {
    margin : 4px !important
}

.config_column_checkbox {
    width: 3.75rem;
    display: flex;
    justify-content: space-between;
}

.config_column_lib {
    display: flex;
    padding-top: 7px;
}

.config_column_element_repeated {
    cursor: move;
    display:flex;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
}

.cursor_config_tooltip_icon {
    height: 23px;
    font-size: 23px;
    padding-top: 2px;
}

.panel_config_tooltip_height {
    min-height : 31.25rem;
}

.md-chips.md-default-theme .md-chip, md-chips .md-chip{
    background-color : var(--dc-secondary) !important
}

.padding-left-0-i {
    padding-left: 0 !important;
}

.padding-right-0-i {
    padding-right: 0 !important;
}

.overflow-auto {
    overflow: auto;
}

.icon-30-f-disabled {
    width: 30px;
    height: 30px;
    color: var(--ag-grey3);
    pointer-events: none;
    cursor: not-allowed;
}

.icon-disabled {
    color: var(--ag-grey3);
    pointer-events: none;
    cursor: not-allowed;
}

.formula-options-icon {
    font-size: 1.5em !important;
    position: relative;
    top: 2px;
}

.toggle.disabled {
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: none;
  opacity: 0.65;
}

#progressBarStatus {
    display: inline-block;
}

.complete .dx-progressbar-range {
    background-color: green;
}

.import-db-toggle .toggle {
    width: 70px !important;
}

.import-db-schema {
    margin-left: 11px !important;
}

.orange-warning {
    color: #fd7e14 !important;
}

.dx-header-customize {
    width:100% !important;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.dx-grid-header-parent > .dx-column-indicators > span  {
    float: left;
}

.dx-grid-header-parent > .dx-column-indicators, .dx-grid-header-parent > .dx-datagrid-text-content {
    float: left;
    position: relative;
    min-height: 1px;
    width:100% !important;
}

#integrateImportFormulas {
    bottom: 10px;
    right: 0px;
}

.valid-element {
    font-weight: 600;
    color: green;
}

.invalid-element {
    font-weight: 600;
    color: red;
}

.modal-width-1200 {
    width: 1200px;
}

.tag_width {
   width: 120px;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.init_db_container {
display: grid;
grid-template-areas:
    "area1 area2";
grid-template-columns: 1fr 1fr;
grid-column-gap : 50px;
height: 100%;
}

.init_db_card_one {
  grid-area: area1;
}

.init_db_card_two {
  grid-area: area2;
  height: 100%;
}

.column-area {
  margin-top: 78px;
}

.top-space-20 {
  margin-top: 20px;
}

.scroll-on-advance {
  overflow: auto;
  height: 430px;
}

.scroll-without-advance {
  overflow: auto;
  height: 500px;
}

.data-preview {
  height: 450px;
  width: 1100px !important
}

.number_circle_layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.number_circle_style {
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #070808;
    border-radius: 10px;
}

.no_consuming_elements_found_container {
    display: flex;
    justify-content:center;
    height: 300px;
}

.no_data_found_container_layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.no_data_found_font_style {
    color: #999;
    font-size: 17px;
}

.inactive-formula {
  cursor: default;
  color: gray;
  font-size: 0.8em;
}

.inactive-formula-icon {
  font-size: 1.4em;
  color: #707070;
}

.width_70_percent {
  width: 70%;
}

.advanced_params_container {
  width: 70%;
  display: grid;
  grid-template-areas: "labelArea valueArea";
  grid-template-columns: 0.90fr 1.10fr;
  grid-column-gap: 0.3125em;
}

.labelArea_grid_column {
 grid-area: labelArea;
}

.valueArea_grid_column {
 grid-area: valueArea;
}

.general_container {
 margin-top: 20px;
 width: 60%;
}

.flex_display {
    display: flex;
}

.establishment_logo_style {
    order: 2px #afafaf solid;
}

.project-edit-dropdown-menu {
     margin-left: -100px;
     width: 80px;
     z-index: 1001;
}

.duplicate_dropdown_menu {
    width: 100%;
    display:flex;
    align-items: center;
    padding-left: 0.9375em;
}

.duplicate_dropdown_menu_position {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.counter_style {
    height: 11px;
		font-family: var(--font-family-title);
    font-style: normal;
    font-weight: 275;
    font-size: 10px;
		width: 18px;
		display: flex;
		justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F1F1F0;


    /* ag-primary */

    color: #333333;

    border-radius: 50px;
}

.disabled-tab:hover {
	pointer-events: auto;
}

.disabled-tab:active {
	pointer-events: none;
	cursor: not-allowed;
}

.formula-tooltip {
	white-space: normal;
	max-width: 100%;
	word-break: break-all;
}

.formula-truncate {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-bottom: 2px !important;
}



