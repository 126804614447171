@use "@angular/material" as mat;
@use 'devextreme/scss/bundles/dx.light' as dx-light;
//@use 'devextreme/scss/bundles/dx.material.teal.light.compact' as dx-teal-compact;
@use 'devextreme/scss/bundles/dx.common' as dx-common;

@import "scss/dependencies";
@import "scss/dc-global";
@import "scss/typography";
@import "scss/variables";
@import "scss/generics-theme";

// includes custom material theme
@include mat.all-component-themes($gd-theme);

// styles overrides per component
@import "scss/app-dialog";
@import "scss/scrollbar";
@import "scss/common";

// styles overrides to move to one file per component
app-test-cmp {
	.mat-icon {
		width: 50px !important;
		height: 50px !important;
	}
}

app-export-template-details {
	app-nav-doc {
		.mat-icon {
			aspect-ratio: 1;
			width: 21px;
		}
	}
}

app-tag-configurator {
	.app-tags {
		.mat-form-field-flex {
			padding-top: 0;
			.mat-form-field-infix {
				padding: 0;
			}
		}
	}
}
