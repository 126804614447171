:root {
  --grey-background-color: #EEEEEF;
  --white-background-color: #FFF;
  --red-color : #D71414;
  --dc-primary-color: #666;
  --ag-primary-color: #333;
  --duplicate-header-template-row:  0.12fr;
  --export-header-template-row:  0.03fr;
  --duplicate-project-template-row:  0.88fr;
  --export-project-template-row:  0.88fr;
  --footer-template-row:  0.1fr;
}

.project_to_import_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 90vh;
}

.project_to_import {
	display: flex;
  justify-content: center;
}

.project_toimport_header {
 		height: 100%;
		display: flex;
		align-items: center;
		font-family:  var(--font-family-title);
		font-weight: 550;
		font-size: 1em;
    color: var(--ag-primary-color);
}

.file_to_import_choice {
	font-family: var(--font-family-title);
	font-weight: 400;
	font-size: 20px;
}

.import_project_header_informations {
	height: 90%;
	padding: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;;
}

.select_file_to_upload_container {
	display: flex;
	justify-content: center;
	padding-top: 32px;
}

.select_file_to_upload_dash {
	display:flex;
	justify-content: center;
	width: 60%;
}

.source_project_informations_container {
	background-color: #EEEEEF;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 86px;
	padding: 12px;
}

.source_project_informations {
	display: flex;

}
