/*
div { border: solid 1px #000}
*/

.metadata-popover-label {
    font-size: 14px;
    font-weight: bold;
}

.thick-panel-heading {
    padding: 2px;
    border-radius: 0 !important;
    background: none !important;
    background-color: var(--ag-grey4) !important;
    margin-bottom: 10px;
}

.metadata-advanced {
    z-index: 1000000000;
}


.panel-body-meta {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    margin-top: -10px;
    padding: 5px 0;
    border: solid 1px var(--ag-grey4);
}

.metadata-img {
   /* border: 1px solid #ced4d8; */
    padding-top: 8px;
    padding-bottom: 8px;
    width: 70px;
}

/* metadata.html */
.metadata-center-label {
    text-align: center !important;
}

.metadata-code {
    font-size: 13px;
}

.metadata-code-copy {
    font-size: 1.4em;
}

.tag-dropdown-item:focus, .tag-dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #e8e8e8;
}

.metadata-add-tag {
    font-size: 1.5em;
    margin-top: 5px;
    color: #578445;
}

.tag-element {
    margin: 5px 2px 2px;
    padding: 0 5px;
    border-radius: 3px;
    cursor: move;
    width: 120px;
}

.tag-element-list {
    width: 13px;
    height: 13px;
    margin-top: 3px;
}

.tag-element-border {
    border: 1px solid #cfcfcf;
}
