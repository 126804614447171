.width-300-px {
    width: 300px;
}

.width-237-px {
    width: 237px;
}

.width-200-px {
    width: 200px;
}

.width-110-px {
    width: 110px;
}

.width-130-px {
    width: 130px;
}

.width-70-px {
    width: 70px;
}

.width-60-px {
    width: 60px;
}

.width-100 {
    width: 100%;
}

.width-80 {
    width: 80%;
}

.width-74 {
    width: 74%;
}

.width-70 {
    width: 70%;
}

.width-60 {
    width: 60%;
}

.width-56 {
    width: 56%;
}

.width-52 {
    width: 52%;
}

.width-50 {
    width: 50%;
}

.width-50-px {
    width: 50px;
}

.width-43 {
    width: 43%;
}

.width-44 {
    width: 44%;
}

.width-45 {
    width: 45%;
}

.width-42 {
    width: 42%;
}

.width-40 {
    width: 40%;
}

.width-35 {
    width: 35%;
}

.width-40 {
    width: 40%;
}

.width-33 {
    width: 33%;
}

.width-30 {
    width: 30%;
}

.width-32 {
    width: 32%;
}

.width-30-px {
    width: 30px;
}

.width-27 {
    width: 27%;
}

.width-25 {
    width: 25%;
}

.width-20 {
    width: 20%;
}

.width-18 {
    width: 18%;
}

.width-15 {
    width: 15%;
}

.width-13 {
    width: 13%;
}

.width-11 {
    width: 11%;
}

.width-10 {
    width: 10%;
}

.width-10-px {
    width: 10px;
}

.width-8 {
    width: 7%;
}

.width-7 {
    width: 7%;
}

.width-5 {
    width: 5%;
}

.width-4 {
    width: 4%;
}

.width-3 {
    width: 3%;
}

.width-inherit {
    width: inherit;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-middle {
    vertical-align: middle;
}

.height-40 {
    height: 40%;
}

.height-45 {
    height: 45%;
}

.height-45-px {
    height: 45px;
}

.height-25-px {
    height: 25px;
}

.max-width-70-px {
    max-width: 70px;
}