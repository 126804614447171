.catalogue {
  display: flex;
  flex-direction: column;
  gap : 1.05em;
  height: 90vh;

  --search-button-color: hsl(180deg 100% 20%);
  --tag-color: hsl(180deg 40% 70%);
}

.catalogue__header {
}

.catalogue__header__header {
  padding: 0.62em 0 0 0.62em;
}

.catalogue__searchArea {
  display: flex;
  flex-direction: column;
  border-radius: 0.25em;
  padding: 0.5em 0.5em 1.25em;
  background-color: var(--ag-grey4);
}

.search__button {
  border-radius : 0.25em;
  background-color: var(--search-button-color);
  color: white;
  margin-left : 1rem;
  padding: 3px 6px;
  border: 1px solid transparent;
}

.search__button--is-disabled {
  background-color: var(--ag-grey3);
  color: black;
}

.paragraph_container {
    padding: 0.62em 0.62em 0;
}

.search_container {
  display: flex;
  padding: 0.62em 0.62em 1.25em;
  align-items: center;
  margin-top: 1.05em;
}

.search_input {
  width: 40rem;
  border-radius: 0.25em;
}

.catalogue__searchArea__tags {
  display: flex;
  padding: 0 0.5em;
  width: 50%;
  flex-wrap: wrap;
  row-gap: 1em;
}

.catalogue__searchArea__single-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.05em;
  border: var(--tag-color) 1px solid;
  border-radius: 0.25em;
  padding: 0.15em 0.35em;
  background-color: var(--tag-color);
  flex: 0 1 10%;
}

.catalogue__searchArea__single-tag > i {
  margin-left: 0.55em;
}

.catalogue__dataCatalogue {
  flex-grow: 2;
}

.catalog-data-grid {
  width: 100%;
  height: 75%;
}

.data-catalog-actions {
  display: flex;
  justify-content: center;
}

.data-catalog-actions-wrapper {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.data-catalog-item-label {
  cursor: pointer;
  color: #252525;
  padding-right: 5px;
}

.data-catalog-item-label:hover, .data-catalog-item-label:active, .data-catalog-item-label:visited {
  cursor: pointer;
  color: #252525;
}

.data-catalog-item-label:active, .data-catalog-item-label:visited {
  text-decoration: none;
}
