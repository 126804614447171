.export-template-list-container {
  display: grid;
  grid-template-areas:
    "filterProject actionsOnElement"
    "list list";
  grid-gap: 10px;
}

.export-template-list-container__switch-project-filter {
  grid-area: filterProject;
}

.export-template-list-container__switch-project-filter__items {
	display: flex;
	grid-template-areas:
    "filtetItem filtetItem";
	grid-gap: 5px;
}

.export-template-list-container__actions-on-elements {
  grid-area: actionsOnElement;
  justify-self: end;
}

.export-template-list-container__list {
  grid-area: list;

}

.export-template-list-container__switch-project-filter__item {
	grid-area: filtetItem;
  display: flex;
  align-items: center;
	gap: 30px;
}

.export-template-list-container__switch-project-filter__item label {
  font-weight: bold;
}

.actions {
  gap: 10px;
}
