/*************** SCROLLBAR **********************************/
/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: var(--ag-grey3) var(--ag-grey4);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: var(--ag-grey4);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--ag-grey3);
	border-radius: 20px;
	border: 3px solid var(--ag-grey4);
}

*::-webkit-scrollbar-thumb:hover {
	background:  var(--ag-grey3);
}
