.modal-body-bulk {
    overflow-y: hidden !important;
    padding-top: 0 ! important;
}

.sources-item {
    border: 1px solid #DDD;
    padding-bottom: 10px;
    padding-top: 10px;
}

.bulk-mapping-title {
    font-weight: bold;
}

.bulk-mapping {
    max-height: 250px;
    min-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.bulk-mapping-delete {
    padding: 5px 0 0 9px;
}
