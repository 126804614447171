.user-icon {
    margin-right: 5px;
}

.user-right-container {
    padding-right: 5px;
}

.user-bar {
    border: solid 1px #ebf0fa;
    margin-left: 28px;
    margin-right: 30px;
}

.user-roles {
    display: flex;
    justify-items: center;
}

.user-role {
    margin: 0 1.0em 0 0.25em
}

.user-role--is-member {
    display: flex;
    position: relative;
}

.user-role--is-member::after {
    content: attr(data-currentMemberRole);
    position: absolute;
    top: -1em;
    right: -0.8em;
    font-size: 0.7em;
    background: var(--gd-primary);
    color: white;
    width: 1.1em;
    height: 1.1em;
    text-align: center;
    line-height: 1.1em;
    border-radius: 50%;
    box-shadow: 0 0 1px #333;
}

.user-role--is-project-admin {
    display: flex;
    position: relative;
}

.user-role--is-project-admin::after {
    content: attr(data-currentProjectRole);
    position: absolute;
    top: -1em;
    right: -0.8em;
    font-size: 0.7em;
    background: var(--gd-primary);
    color: white;
    width: 1.1em;
    height: 1.1em;
    text-align: center;
    line-height: 1.1em;
    border-radius: 50%;
    box-shadow: 0 0 1px #333;
}
